// Class definitions for the CareHub application

export interface ActivityDetailRoles {
    ID: number,
    ActivityDetailCode: string,
    ActivityDetailDescription: string,
    Duration: number | null,
    StaffMin: number,
    StaffMax: number,
    ColorCode: string,
    RSRolesIDs: string | null
}

export interface ActivityDetailsWithRoles {
    ID: number,
    ActivityDetailCode: string,
    ActivityDetailDescription: string,
    Duration: number | null,
    StaffMin: number,
    StaffMax: number,
    ColorCode: string,
    Roles: Roles[]
}

export interface Category {
    ID: number,
    Name: string,
    BaseColor: string,
    Level: number
}

export interface CategoryCreate {
    Name: string;
    BaseColor?: string;
    Level?: number;
}

export interface CategoryUpdate {
    Name: string;
    BaseColor: string;
    Level: number;
}

export interface CategoryData {
    ID: number,
    Name: string,
    BaseColor: string,
    Level: number,
    tableID: number,
    CategoryID: number
    dataID: number
}

export interface Roles {
    ID: number,
    RoleCode: string,
    RoleDescription: string,
    SortOrder: number
}

export interface StaffRoles {
    ID: number,
    FirstName: string,
    LastName: string,
    Inactive: boolean,
    EmployeeID: number,
    TitleID: number,
    DepartmentID: number,
    EmergencyAvailability: boolean,
    RSRolesIDs: string | null
}

export interface StaffWithRoles {
    ID: number,
    FirstName: string,
    LastName: string,
    Inactive: boolean,
    EmployeeID: number,
    TitleID: number,
    DepartmentID: number,
    EmergencyAvailability: boolean,
    Roles: Roles[]
}

export function parseStaffRoles(apiResponse: any[]): StaffRoles[] {
    return apiResponse.map((staff) => ({
        ...staff,
        Inactive: Boolean(staff.Inactive),
        EmergencyAvailability: Boolean(staff.EmergencyAvailability === 'Y')
    }));
}

export function addRolesToActivityDetails(activityDetails: ActivityDetailRoles[], roles: Roles[]): ActivityDetailsWithRoles[] {
    return activityDetails.map((activityDetail) => {
        let activityRoles: Roles[] = [];

        if (activityDetail.RSRolesIDs) {
            const roleIDs = activityDetail.RSRolesIDs.split(',').map(Number);
            activityRoles = roles.filter((role) => roleIDs.includes(role.ID));
        }

        return {
            ...activityDetail,
            Roles: activityRoles
        };
    });
}

export function addRolesToStaff(staffRoles: StaffRoles[], roles: Roles[]): StaffWithRoles[] {
    return staffRoles.map((staff) => {
        let staffRoles: Roles[] = [];

        if (staff.RSRolesIDs) {
            const roleIDs = staff.RSRolesIDs.split(',').map(Number);
            staffRoles = roles.filter((role) => roleIDs.includes(role.ID));
        }

        return {
            ...staff,
            Roles: staffRoles
        };
    }
    );
}
