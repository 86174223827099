import { Component, OnInit } from '@angular/core';
import { CareHubService } from 'src/app/services/carehub.service';
import { ActivityService } from 'src/app/services/activity.service';
import { StaffWithRoles } from 'src/app/models/carehub.model';

interface RoleSummary {
  roleDescription: string;
  count: number;
}

@Component({
  selector: 'app-carehub',
  templateUrl: './carehub.component.html',
  styleUrls: ['./carehub.component.css']
})
export class CareHubComponent implements OnInit {
  staffWithRoles: StaffWithRoles[] = [];
  roleSummary: RoleSummary[] = [];
  staffColumns: string[] = ['carerId', 'name', 'roles'];
  summaryColumns: string[] = ['roleDescription', 'count'];
  isSummaryExpanded: boolean = false;

  constructor(private careHubService: CareHubService, private activityService: ActivityService) { }

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    this.careHubService.getStaffWithRoles().subscribe(staffWithRoles => {
      this.staffWithRoles = staffWithRoles;
      this.calculateRoleSummary();
    });
  }

  calculateRoleSummary() {
    const roleCounts = new Map<string, number>();
    
    this.staffWithRoles.forEach(staff => {
      staff.Roles.forEach(role => {
        const count = roleCounts.get(role.RoleDescription) || 0;
        roleCounts.set(role.RoleDescription, count + 1);
      });
    });

    const otherRoles: RoleSummary = { roleDescription: 'Other', count: 0 };
    this.roleSummary = Array.from(roleCounts.entries())
      .map(([roleDescription, count]) => ({ roleDescription, count }))
      .filter(role => {
        if (role.count > 1) {
          return true;
        } else {
          otherRoles.count += 1;
          return false;
        }
      })
      .sort((a, b) => b.count - a.count);

    if (otherRoles.count > 0) {
      this.roleSummary.push(otherRoles);
    }
  }

  toggleSummary() {
    this.isSummaryExpanded = !this.isSummaryExpanded;
  }
}