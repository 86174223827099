import { Component, OnInit } from '@angular/core';
import { CareHubService } from '../../services/carehub.service';
import { ActivityDetailsWithRoles } from '../../models/carehub.model';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {
  activityDetailsWithRoles: ActivityDetailsWithRoles[] = [];
  activeTab: string = 'activity details with roles';

  constructor(
    private careHubService: CareHubService
  ) {}

  ngOnInit(): void {
    this.loadAllData();
  }

  loadAllData(): void {
    this.careHubService.getActivityDetailsWithRoles().subscribe(
      (activityDetailsWithRoles) => {
        this.activityDetailsWithRoles = activityDetailsWithRoles;
      },
      (error: any) => console.error('Error loading data:', error)
    );
  }

  setActiveTab(tab: string): void {
    this.activeTab = tab.toLowerCase();
  }
}
