<div class="roster-container">
  <div *ngIf="isLoading" class="loading-overlay">
    <div class="loading-content">
      <img src="assets/hm-loading-new.gif" alt="Loading" class="loading-gif">
      <p class="loading-text mat-h2">Waiting for data from the eCase server...</p>
    </div>
  </div>

  <div id="CommunityRosterNew" [class.hidden]="isLoading">
    <div #scheduler_here class="dhx_cal_container" style="width: 100%; height: 90vh;">
      <div class="dhx_cal_navline">
        <div class="dhx_cal_prev_button" title="Previous">&nbsp;</div>
        <div class="dhx_cal_next_button" title="Next">&nbsp;</div>
        <div class="dhx_cal_today_button"></div>
        <div class="dhx_cal_date"></div>
      </div>
      <div class="dhx_cal_header"></div>
      <div class="dhx_cal_data"></div>
    </div>
  </div>
</div>