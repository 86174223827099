import { Component, OnInit } from '@angular/core';
import { StaffService, Role, Staff, StaffRole } from 'src/app/services/staff.service';

@Component({
  selector: 'app-staff',
  templateUrl: './staff.component.html',
  styleUrls: ['./staff.component.css']
})
export class StaffComponent implements OnInit {
  staff: Staff[] = [];
  roles: Role[] = [];
  staffRoles: StaffRole[] = [];
  staffWithRoles: { staff: Staff, roles: Role[], roleColors: { [key: string]: string } }[] = [];
  sortType: 'name' | 'role' = 'name';
  colorPalette: string[] = [
    '#FF5733', '#33FF57', '#3357FF', '#F3FF33', '#FF33F3',
    '#33FFF3', '#F333FF', '#FF3363', '#63FF33', '#3363FF'
  ];
  roleColorMapping: { [key: string]: string } = {};

  constructor(private staffService: StaffService) { }

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    this.staffService.getStaff().subscribe(staff => {
      this.staff = staff;
      this.combineData();
    });
    this.staffService.getRoles().subscribe(roles => {
      this.roles = roles;
      this.createRoleColorMapping();
      this.combineData();
    });
    this.staffService.getStaffRoles().subscribe(staffRoles => {
      this.staffRoles = staffRoles;
      this.combineData();
    });
  }

  createRoleColorMapping() {
    this.roles.forEach((role, index) => {
      if (role && role.RoleDescription) {
        this.roleColorMapping[role.RoleDescription] = this.colorPalette[index % this.colorPalette.length];
      }
    });
  }

  combineData() {
    if (this.staff.length && this.roles.length && this.staffRoles.length) {
      this.staffWithRoles = this.staff.map(s => {
        const roles = this.staffRoles
          .filter(sr => sr.RSAuthenID === s.ID)
          .map(sr => this.roles.find(r => r && r.ID === sr.RSRolesID))
          .filter(r => r !== undefined) as Role[];

        const roleColors = roles.reduce((colors, role) => {
          if (role && role.RoleDescription) {
            colors[role.RoleDescription] = this.roleColorMapping[role.RoleDescription];
          }
          return colors;
        }, {} as { [key: string]: string });

        return {
          staff: s,
          roles: roles,
          roleColors: roleColors
        };
      });
      this.sortData();
    }
  }

  sortData() {
    if (this.sortType === 'name') {
      this.staffWithRoles.sort((a, b) => {
        const aFirstName = a.staff.FirstName || '';
        const bFirstName = b.staff.FirstName || '';
        return aFirstName.localeCompare(bFirstName);
      });
    } else if (this.sortType === 'role') {
      this.staffWithRoles.sort((a, b) => {
        const aRoles = a.roles.map(r => r?.RoleDescription || '').join(', ');
        const bRoles = b.roles.map(r => r?.RoleDescription || '').join(', ');
        return aRoles.localeCompare(bRoles);
      });
    }
  }

  setSortType(type: 'name' | 'role') {
    this.sortType = type;
    this.sortData();
  }

  updateeCase() {
    this.staffService.updateEcaseStaff().subscribe(() => {
      this.loadData();
      alert('Staff data has been refreshed from eCase');
    });
    this.staffService.updateEcaseStaffRoles().subscribe(() => {
      this.loadData();
      alert('Staff role data has been refreshed from eCase');
    });    
  }
}