<mat-card class="shift-card">
  <table mat-table [dataSource]="shifts" class="mat-elevation-z8">
    <ng-container matColumnDef="staff">
      <th mat-header-cell *matHeaderCellDef> Shift Name </th>
      <td mat-cell *matCellDef="let shift"> {{ shift.staff.FirstName }} {{ shift.staff.LastName }} </td>
    </ng-container>

    <ng-container *ngFor="let bucket of buckets" [matColumnDef]="bucket.Name">
      <th mat-header-cell *matHeaderCellDef> {{ bucket.Name }} </th>
      <td mat-cell *matCellDef="let shift">
        <mat-checkbox
          [checked]="isChecked(bucket.ID, shift.staff.ID)"
          (change)="onCheckboxChange($event, bucket.ID, shift.staff.ID)">
        </mat-checkbox>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</mat-card>