// api-base.service.ts
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable, throwError, from, BehaviorSubject, EMPTY } from 'rxjs';
import { catchError, switchMap, filter, take, mergeMap, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { MsalService } from '@azure/msal-angular';
import { AuthenticationResult, InteractionRequiredAuthError } from '@azure/msal-browser';

@Injectable({
  providedIn: 'root'
})
export class BaseApiService {
  protected apiUrl = environment.backend.uri;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private isRefreshing = false;

  constructor(private http: HttpClient, private msalService: MsalService) { }

  private getAuthHeaders(): Observable<HttpHeaders> {
    return this.getAccessToken().pipe(
      map((token: string) => new HttpHeaders({
        Authorization: `Bearer ${token}`
      }))
    );
  }

  protected get<T>(url: string): Observable<T> {
    return this.getAuthHeaders().pipe(
      mergeMap(headers => this.http.get<T>(url, { headers })),
      catchError(error => this.handleError(error, () => this.get<T>(url)))
    );
  }

  protected post<T>(url: string, body: any): Observable<T> {
    return this.getAuthHeaders().pipe(
      mergeMap(headers => this.http.post<T>(url, body, { headers })),
      catchError(error => this.handleError(error, () => this.post<T>(url, body)))
    );
  }

  protected put<T>(url: string, body: any): Observable<T> {
    return this.getAuthHeaders().pipe(
      mergeMap(headers => this.http.put<T>(url, body, { headers })),
      catchError(error => this.handleError(error, () => this.put<T>(url, body)))
    );
  }

  protected delete<T>(url: string): Observable<T> {
    return this.getAuthHeaders().pipe(
      mergeMap(headers => this.http.delete<T>(url, { headers })),
      catchError(error => this.handleError(error, () => this.delete<T>(url)))
    );
  }

  private handleError(error: HttpErrorResponse, retryCall: () => Observable<any>): Observable<any> {
    if (error.status === 401) {
      return this.handle401Error().pipe(
        mergeMap(() => retryCall())
      );
    }
    return throwError(() => error);
  }

  private handle401Error(): Observable<any> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.refreshToken().pipe(
        switchMap((token: string) => {
          this.isRefreshing = false;
          this.refreshTokenSubject.next(token);
          return from(Promise.resolve(token));
        }),
        catchError((error) => {
          this.isRefreshing = false;
          this.msalService.logoutRedirect();
          return throwError(() => new Error('Token refresh failed'));
        })
      );
    } else {
      return this.refreshTokenSubject.pipe(
        filter(token => token !== null),
        take(1)
      );
    }
  }

  private refreshToken(): Observable<string> {
    return from(this.msalService.acquireTokenSilent({
      scopes: environment.backend.scopes,
      account: this.msalService.instance.getActiveAccount()!
    })).pipe(
      map((result: AuthenticationResult) => result.accessToken),
      catchError((error) => {
        console.error('Silent token acquisition failed', error);
        if (error instanceof InteractionRequiredAuthError) {
          return from(this.msalService.acquireTokenPopup({
            scopes: environment.backend.scopes
          })).pipe(
            map((result: AuthenticationResult) => result.accessToken)
          );
        }
        return throwError(() => error);
      })
    );
  }

  private getAccessToken(): Observable<string> {
    const account = this.msalService.instance.getActiveAccount();
    if (account) {
      return from(this.msalService.acquireTokenSilent({
        scopes: environment.backend.scopes,
        account: account
      })).pipe(
        map((result: AuthenticationResult) => result.accessToken),
        catchError(() => this.refreshToken())
      );
    }
    return throwError(() => new Error('No active account'));
  }

  public handleAuthError(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.handle401Error().pipe(
      mergeMap((token: string) => {
        const authReq = request.clone({
          setHeaders: { Authorization: `Bearer ${token}` }
        });
        return next.handle(authReq);
      })
    );
  }
}