<div id="CommunityRosterNew">
    <div #scheduler_here class="dhx_cal_container" style="width: 100%; height: 80vh;">
        <div class="dhx_cal_navline">
            <div class="dhx_cal_prev_button" title="Previous">&nbsp;</div>
            <div class="dhx_cal_next_button" title="Next">&nbsp;</div>
            <div class="dhx_cal_today_button"></div>
            <div class="dhx_minical_icon" id="dhx_minical_icon" (click)="show_minical()" title="Choose Date">&nbsp;</div>
            <div class="dhx_cal_date"></div>
            <div class="custom_nav_buttons">
                <span title="Column Width">{{ columnWidth }}px </span>
                <button (click)="changeColumnWidth(-20)" title="Decrease Column Width">-</button>
                <button (click)="changeColumnWidth(20)" title="Increase Column Width">+</button>
                <button (click)="toggleDarkMode()" title="Toggle Dark Mode">🌓︎</button>
                <div class="toggle-container" title="Auto-allocate key staff">
                    <label class="switch">
                        <input type="checkbox" [(ngModel)]="autoAllocateKeyStaff" (change)="onAutoAllocateToggle()">
                        <span class="slider round"></span>
                    </label>
                    <span>Auto-allocate</span>
                </div>
            </div>
            <app-bucket-dropdown (bucketChange)="onBucketChange($event)" title="Select Category"></app-bucket-dropdown>
            <app-publish [pendingChangesCount]="pendingChangesCount" (publishChanges)="publishPendingChanges()"
                title="Pending Changes"></app-publish>
        </div>
        <div class="dhx_cal_header"></div>
        <div class="dhx_cal_data"></div>
    </div>
</div>