<div class="publish-container">
    <div class="spinner-container">
        <div *ngIf="publishing" class="progress-spinner">
            <mat-progress-spinner mode="indeterminate" [diameter]="40" strokeWidth="4"></mat-progress-spinner>
        </div>
        <div class="pending-count">{{ pendingChangesCount }}</div>
    </div>
    <button mat-raised-button color="primary" (click)="publish()" [disabled]="publishing || pendingChangesCount === 0"
        class="publish-button">
        Publish
    </button>
</div>