import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { GraphService } from '../services/graph.service';

@Injectable({
  providedIn: 'root'
})
export class RosteringGuard {
  constructor(private graphService: GraphService, private router: Router) {}

  canActivate(): Observable<boolean | UrlTree> {
    return this.graphService.isRosteringAllowed$.pipe(
      take(1),
      map(isAllowed => {
        if (isAllowed) {
          return true;
        } else {
          return this.router.createUrlTree(['/']);
        }
      })
    );
  }
}