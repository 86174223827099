import { Component, ElementRef, OnInit, OnDestroy, ViewChild, ViewEncapsulation } from "@angular/core";
import { Subscription } from 'rxjs';
import { format } from 'date-fns';

import { Bucket } from 'src/app/services/bucket.service';
import { HelperService } from "src/app/services/helper.service";
import { Staff } from "src/app/services/staff.service";
import { SchedulerService } from "src/app/services/scheduler.service";

import { PublishComponent } from "src/app/components/ui_elements/publish/publish.component";


@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-reallocate',
  templateUrl: './reallocate.component.html',
  styleUrls: ['./reallocate.component.css', './rosterView.css'],
  providers: [SchedulerService]
})
export class ReallocateComponent implements OnInit, OnDestroy {
  @ViewChild("scheduler_here", { static: true }) schedulerContainer!: ElementRef;
  @ViewChild(PublishComponent) publishComponent!: PublishComponent; // Add ViewChild for PublishComponent
  selectedBucket: Bucket | null = null;
  selectedStaff: Staff[] = [];
  columnWidth: number = 40;
  pendingChangesCount: number = 0;
  private subscriptions: Subscription = new Subscription();

  constructor(
    private helperService: HelperService,
    private schedulerService: SchedulerService
  ) { }

  ngOnInit() {
    this.columnWidth = this.helperService.getStoredValue('columnWidth', 40);
    this.schedulerService.initScheduler(this.schedulerContainer);

    const nextButton = document.querySelector('.dhx_cal_next_button');
    const prevButton = document.querySelector('.dhx_cal_prev_button');

    nextButton?.addEventListener('click', () => this.buttonClickHandler());
    prevButton?.addEventListener('click', () => this.buttonClickHandler());

    this.subscriptions.add(
      this.schedulerService.pendingChangesCount$.subscribe(count => {
        this.pendingChangesCount = count;
        if (this.publishComponent) {
          this.publishComponent.pendingChangesCount = count;
        }
      })
    );

    this.subscriptions.add(
      this.schedulerService.publishComplete$.subscribe(complete => {
        if (complete) {
          this.onPublishComplete();
        }
      })
    );
  }

  buttonClickHandler() {
    if (this.selectedBucket) {
      this.schedulerService.changeDay(this.selectedBucket.ID);
    }
  }

  onBucketChange(bucket: Bucket) {
    this.selectedBucket = bucket;
    this.selectedStaff = [];
    this.loadData();
  }

  onStaffChange(staff: Staff[]) {
    this.selectedStaff = staff;
    this.loadData();
  }

  loadData() {
    if (!this.selectedBucket || this.selectedStaff.length != 2) return;

    const formattedDate = format(this.schedulerService.scheduler?.getState().date, 'yyyy-MM-dd');
    this.schedulerService.updateTimelineView(this.selectedStaff, this.columnWidth, false);
    this.schedulerService.loadTasks(formattedDate, this.selectedBucket!.ID);
    this.updatePendingChangesCount();
  }

  moveAll(direction: string) {
    if (this.selectedStaff.length != 2) return;
    this.schedulerService.moveAllEvents(this.selectedStaff, direction);
  }

  publishPendingChanges() {
    this.schedulerService.publishPendingChanges();
  }

  private onPublishComplete() {
    if (this.publishComponent) {
      this.publishComponent.onPublishComplete();
    }
  }

  changeColumnWidth(change: number) {
    this.columnWidth = Math.max(20, Math.min(100, this.columnWidth + change));
    this.helperService.storeValue('columnWidth', this.columnWidth);
    var timeline = this.schedulerService.scheduler?.getView();
    if (timeline) {
      timeline.column_width = this.columnWidth;
      this.schedulerService.scheduler?.setCurrentView();
    }
  }

  show_minical() {
    if (this.selectedBucket) {
      this.schedulerService.show_minical(this.selectedBucket.ID);
    }
  }

  private updatePendingChangesCount() {
    const events = this.schedulerService.scheduler?.getEvents();
    if (!events) return;
    this.pendingChangesCount = events.filter(event => event.pending_change).length;
  }


  ngOnDestroy() {
    this.subscriptions.unsubscribe();
    if (this.schedulerService.scheduler) this.schedulerService.scheduler.destructor();
  }
}
