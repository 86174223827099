export class AppointmentStatus {
    static APPOINTMENT_AVAILABILITY = "APPOINTMENT_AVAILABILITY";
    static APPOINTMENT_CANCELLED = "APPOINTMENT_CANCELLED";
    static APPOINTMENT_UNALLOCATED = "APPOINTMENT_UNALLOCATED";
    static APPOINTMENT_ALLOCATED = "APPOINTMENT_ALLOCATED";
    static APPOINTMENT_PARTIALLY_ALLOCATED = "APPOINTMENT_PARTIALLY_ALLOCATED";
    static APPOINTMENT_UNAPPROVED = "APPOINTMENT_UNAPPROVED";
    static APPOINTMENT_APPROVED = "APPOINTMENT_APPROVED";
    static APPOINTMENT_IN_WAITING_ROOM = 'APPOINTMENT_IN_WAITING_ROOM';
    static APPOINTMENT_STARTED = 'APPOINTMENT_STARTED';
    static APPOINTMENT_FAILED_TO_ATTEND = 'APPOINTMENT_FAILED_TO_ATTEND';
    static APPOINTMENT_NOT_ARRIVED = 'APPOINTMENT_NOT_ARRIVED';
    static APPOINTMENT_COMPLETED = 'APPOINTMENT_COMPLETED';

    static APPOINTMENT_CLASSES = {
        [AppointmentStatus.APPOINTMENT_AVAILABILITY]: "appointment-availability",
        [AppointmentStatus.APPOINTMENT_CANCELLED]: "appointment-cancelled",
        [AppointmentStatus.APPOINTMENT_UNALLOCATED]: "appointment-unallocated",
        [AppointmentStatus.APPOINTMENT_ALLOCATED]: "appointment-allocated",
        [AppointmentStatus.APPOINTMENT_PARTIALLY_ALLOCATED]: "appointment-partially-allocated",
        [AppointmentStatus.APPOINTMENT_UNAPPROVED]: "appointment-unapproved",
        [AppointmentStatus.APPOINTMENT_APPROVED]: "appointment-approved",
        [AppointmentStatus.APPOINTMENT_IN_WAITING_ROOM]: "appointment-in-waiting-room",
        [AppointmentStatus.APPOINTMENT_STARTED]: "appointment-started",
        [AppointmentStatus.APPOINTMENT_FAILED_TO_ATTEND]: "appointment-failed-to-attend",
        [AppointmentStatus.APPOINTMENT_COMPLETED]: "appointment-completed"
    };

    static getAppointmentOverallStatus(appointment: any, isAutoAllocationPreview = false): string {
        let appointmentStatus = "";
        // check for null values
        if (!appointment.activity_instance) {
            console.log('No activity instance found for appointment', appointment);
            return appointmentStatus;
        }
        if (!appointment.activity_instance.activity_instance_status_code) {
            console.log('No activity instance status code found for appointment', appointment);
            return appointmentStatus;
        }
        switch (appointment.activity_instance.activity_instance_status_code) {
            case 'AVAILABILITY': {
                appointmentStatus = AppointmentStatus.APPOINTMENT_AVAILABILITY;
                break;
            }
            case 'CANCELLED': {
                appointmentStatus = AppointmentStatus.APPOINTMENT_CANCELLED;
                break;
            }
            case 'INWAITINGROOM': {
                appointmentStatus = AppointmentStatus.APPOINTMENT_IN_WAITING_ROOM;
                break;
            }
            case 'STARTED': {
                appointmentStatus = AppointmentStatus.APPOINTMENT_STARTED;
                break;
            }
            case 'PENDING': {
                appointmentStatus = appointment.isRostered ? AppointmentStatus.APPOINTMENT_ALLOCATED : AppointmentStatus.APPOINTMENT_UNALLOCATED;
                break;
            }
            case 'SCHEDULED': {
                if (appointment.activity_instance.activity_instance_staff_maximum > 1) {
                    let nonCancelledAllocs = (appointment.carer_allocations || []).filter((c: any) => {
                        return [1, '1', true].indexOf(c.is_cancelled) < 0;
                    });

                    if (nonCancelledAllocs.length > 0 && (nonCancelledAllocs.length < appointment.activity_instance.activity_instance_staff_maximum)) {
                        appointmentStatus = AppointmentStatus.APPOINTMENT_APPROVED;
                        break;
                    }
                }

                if (
                    (appointment.vendor.vendor_name && !appointment.vendor.vendor_is_main_vendor) ||
                    (appointment.carer_allocations.length && appointment.carer_allocations[0].is_carer_approved) ||
                    (appointment.carer_allocations.length < appointment.activity_instance.activity_instance_staff_maximum &&
                        appointment.carer_allocations.length >= appointment.activity_instance.activity_instance_staff_minimum)
                ) {
                    appointmentStatus = AppointmentStatus.APPOINTMENT_APPROVED;
                } else if (
                    (appointment.vendor.vendor_name && !appointment.vendor.vendor_is_main_vendor) ||
                    (appointment.carer_allocations.length && !appointment.carer_allocations[0].is_carer_approved) ||
                    (appointment.carer_allocations.length < appointment.activity_instance.activity_instance_staff_maximum &&
                        appointment.carer_allocations.length >= appointment.activity_instance.activity_instance_staff_minimum)
                ) {
                    appointmentStatus = isAutoAllocationPreview ? AppointmentStatus.APPOINTMENT_APPROVED : AppointmentStatus.APPOINTMENT_UNAPPROVED;
                } else {
                    appointmentStatus = AppointmentStatus.APPOINTMENT_UNALLOCATED;
                }

                break;
            }
            case 'DONE': {
                if (!appointment.vendor.vendor_is_main_vendor) {
                    appointmentStatus = AppointmentStatus.APPOINTMENT_COMPLETED;
                } else if (appointment.carer_allocations.length === 0) {
                    appointmentStatus = AppointmentStatus.APPOINTMENT_UNALLOCATED;
                } else {
                    const carerNumberApproved = AppointmentStatus.getNumberApprovedCarers(appointment.carer_allocations);

                    if (carerNumberApproved < appointment.activity_instance.activity_instance_staff_minimum) {
                        appointmentStatus = AppointmentStatus.APPOINTMENT_UNAPPROVED;
                    } else {
                        appointmentStatus = AppointmentStatus.APPOINTMENT_COMPLETED;
                    }
                }
                break;
            }
        }

        return appointmentStatus;
    }

    static getAppointmentClass(appointment: any, isAutoAllocationPreview = false): string {
        const appointmentOverallStatus = AppointmentStatus.getAppointmentOverallStatus(appointment, isAutoAllocationPreview);
        return AppointmentStatus.APPOINTMENT_CLASSES[appointmentOverallStatus] || "";
    }

    static getNumberApprovedCarers(carerAllocations: any[]): number {
        return carerAllocations.reduce((acc, carer) => acc + (carer.is_carer_approved ? 1 : 0), 0);
    }

    static failedToAttend(appointment: any): boolean {
        return appointment.activity_instance.activity_instance_cancelled_status === "Failed to Attend";
    }

    static notStarted(appointment: any): boolean {
        return appointment.activity_instance.activity_instance_status_code === "SCHEDULED" ||
            appointment.activity_instance.activity_instance_status_code === "PENDING";
    }
}