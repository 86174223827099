<div>
  <input type="text" placeholder="Search staff..." (input)="onInput($event)" />
  <div class="staff-selection">
    <div *ngFor="let staff of filteredStaff"
         class="staff-box"
         [class.selected]="selectedStaff.includes(staff)"
         (click)="selectStaff(staff)">
      {{ staff.FirstName }} {{ staff.LastName }}
    </div>
  </div>
</div>
