<h2>Generate Statements</h2>
<mat-form-field>
    <mat-label>Select Clients</mat-label>
    <mat-select [(ngModel)]="selectedClients" multiple>
        <mat-option [value]="'all'">All Clients</mat-option>
        <mat-option *ngFor="let client of clients" [value]="client.ID">
            {{client.FirstName}} {{client.LastName}}
        </mat-option>
    </mat-select>
</mat-form-field>
<mat-form-field>
    <mat-label>Statement Period</mat-label>
    <input matInput [matDatepicker]="picker" [(ngModel)]="statementDate">
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
</mat-form-field>
<button mat-raised-button color="primary" (click)="generateStatements()">
    Generate Statements
</button>
<a *ngIf="downloadLink" [href]="downloadLink" download>Download Statements</a>