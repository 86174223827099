<div class="settings-container">
  <h2>Settings</h2>
  
  <mat-tab-group (selectedTabChange)="setActiveTab($event.tab.textLabel)">
    <mat-tab label="Categories">
      <app-category-management></app-category-management>
    </mat-tab>

    <mat-tab label="Activity Details with Roles">
      <section class="activity-details-roles" *ngIf="activeTab === 'activity details with roles'">
        <h3>Activity Details with Roles</h3>
        <div class="activity-details-container">
          <mat-card *ngFor="let activityDetail of activityDetailsWithRoles" [ngClass]="{'faded': !activityDetail.Roles || activityDetail.Roles.length === 0}">
            <mat-card-header>
              <mat-card-title>
                {{activityDetail.ActivityDetailDescription}}                
              </mat-card-title>
            </mat-card-header>
            <mat-card-content>
              <h4>Roles:</h4>
              <ul *ngIf="activityDetail.Roles && activityDetail.Roles.length > 0">
                <li *ngFor="let role of activityDetail.Roles">
                  {{role.RoleDescription}}
                </li>
              </ul>
              <p *ngIf="!activityDetail.Roles || activityDetail.Roles.length === 0" class="no-roles-text">No roles assigned to this activity.</p>
            </mat-card-content>
          </mat-card>
          <p *ngIf="activityDetailsWithRoles.length === 0">No activity details with roles available.</p>
        </div>
      </section>
    </mat-tab>
  </mat-tab-group>
</div>

<style>
  .faded {
    opacity: 0.7;
  }
  .no-roles-text {
    color: #888;
  }
</style>
