import { Component, ElementRef, OnInit, OnDestroy, ViewChild, ViewEncapsulation } from "@angular/core";
import { SchedulerStatic } from "@dhx/trial-scheduler";
import { RosterService } from "../../services/roster.service";
import { take } from 'rxjs/operators';
import { Subscription } from 'rxjs';


@Component({
    encapsulation: ViewEncapsulation.None,
    selector: 'app-roster',
    templateUrl: './roster.component.html',
    styleUrls: ['./roster.component.css', './rosterView.css', './folders.css']
})
export class RosterComponent implements OnInit, OnDestroy {
    @ViewChild("scheduler_here", { static: false }) schedulerContainer!: ElementRef;

    scheduler!: SchedulerStatic;
    isLoading: boolean = true;
    private isLoadingSubscription!: Subscription;

    constructor(private rosterService: RosterService) { }

    ngOnInit(): void {
        this.isLoadingSubscription = this.rosterService.isLoading$.subscribe(isLoading => {
            this.isLoading = isLoading;
        });

    }

    ngAfterViewInit(): void {
        if (this.schedulerContainer) {
            this.loadData();
        } else {
            console.error('Scheduler container is not available.');
        }
    }

    loadData(): void {
        this.rosterService.setSchedulerContainer(this.schedulerContainer);
        this.rosterService.getYunitData().pipe(take(1)).subscribe(
            yUnitData => {
                this.rosterService.initScheduler(yUnitData);
                this.scheduler = this.rosterService.scheduler!;
                this.rosterService.loadTimelineData();
            },
            error => {
                console.error('Error loading yUnit data:', error);
                this.isLoading = false;
            }
        );
    }


    ngOnDestroy(): void {
        if (this.scheduler) {
            this.scheduler.destructor();
        }
        if (this.isLoadingSubscription) {
            this.isLoadingSubscription.unsubscribe();
        }
    }
}
