import { Injectable } from "@angular/core";
import { SchedulerEvent, createSchedulerEvent } from 'src/app/models/scheduler.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseApiService } from './api-base.service';
import { AppointmentStatus } from "./appointmentStatus";
import { format } from "date-fns";

export const getCssClassForEvent = (appointment: any): string => {
    let classNames = [];
    const statusClass = AppointmentStatus.getAppointmentClass(appointment);
    if (appointment.suggested_carer_string.length > 0 && appointment.suggested_carer_string !== appointment.carer_string) {
        classNames.push('suggested-carer-available');
    }
    if (statusClass) {
        classNames.push(statusClass);
    }
    if (appointment.isStaffEvent) {
        classNames.push('staff-event');
    }
    if (appointment.readonly) {
        classNames.push('readonly');
    }
    if (appointment.activity?.activityType === 'Leave') {
        classNames.push('leave');
    }
    if (appointment.activity?.activityType === 'Unavailability') {
        classNames.push('unavailable');
    }
    return classNames.join(' ');
}

export interface TimelineEvent {
    ID: string,
    activity_id: number,
    activity_text: string,
    client_id: number,
    client_urnumber: string,
    client_name: string,
    carer_string: string | null,
    carer_name: string | null,
    start: string,
    start_time: string,
    end: string,
    end_time: string,
    pending_allocation_change: boolean,
    pending_timing_change: boolean,
    pending_change: boolean,
    quote_detail_id: string | null,
    full_details: SchedulerEvent,
    css_class: string

}

export const createTimelineEvent = (data: SchedulerEvent): TimelineEvent => {
    return {
        ID: data.activity_instance.activity_instance_id,
        activity_id: parseInt(data.activity.services[0].ActivityID),
        activity_text: data.text,
        client_id: parseInt(data.client.client_details[0].residentID),
        client_urnumber: data.client.client_id,
        client_name: data.client.client_name,
        carer_string: data.carer_string || "99999999",
        carer_name: data.carer_allocations.length > 0 ? data.carer_allocations[0].carer_name : null,
        start: data.start_date,
        start_time: data.start_date.slice(-8),
        end: data.end_date,
        end_time: data.end_date.slice(-8),
        pending_allocation_change: false,
        pending_timing_change: false,
        pending_change: false,
        quote_detail_id: data.CCQuoteDetailID,
        full_details: data,
        css_class: getCssClassForEvent(data)
    }
}


@Injectable()
export class EventService extends BaseApiService {

    // API call to get the timeline events
    public getTimelineEvents(bucketId: number, start_date: string, end_date: string): Observable<TimelineEvent[]> {
        return this.get<any>(`${this.apiUrl}/ecase/timeline/keystaff/?bucketid=${bucketId}&start_date=${start_date}&end_date=${end_date}`).pipe(
            map(response => {
                if (response && response.gridData) {
                    return response.gridData.map((item: any) => createTimelineEvent(createSchedulerEvent(item)));
                } else {
                    return [];
                }
            })
        );
    }

    // API call to allocate the service to the carer
    public allocateServiceInstance(careActivityInstanceId: string, rsAuthenId: string): Observable<any> {
        // console.log(`Allocating service instance ${careActivityInstanceId} to carer ${rsAuthenId}`);
        return this.get<any>(`${this.apiUrl}/ecase/allocate/?careActivityInstanceId=${careActivityInstanceId}&rsAuthenId=${rsAuthenId}`);
    }

    public unallocateServiceInstance(careActivityInstanceId: string): Observable<any> {
        // console.log(`Unallocating service instance ${careActivityInstanceId}`);
        return this.get<any>(`${this.apiUrl}/ecase/unallocate/?careActivityInstanceId=${careActivityInstanceId}`);
    }

    public updateKeyStaff(quote_detail_id: string, key_staff_id: string): Observable<any> {
        return this.get<any>(`${this.apiUrl}/quote_detail/update_keystaff/?quote_detail_id=${quote_detail_id}&key_staff_id=${key_staff_id}`);
    }

    public updateActivityTiming(activityInstanceId: string, carerString: string, start: string, end: string): Observable<any> {
        const start_date = encodeURIComponent(format(new Date(start), 'yyyy-MM-dd HH:mm:ss'));
        const end_date = encodeURIComponent(format(new Date(end), 'yyyy-MM-dd HH:mm:ss'));
        // console.log(`Updating activity instance ${activityInstanceId} to start ${start_date} and end ${end_date}`);
        return this.get<any>(`${this.apiUrl}/ecase/update-timing/?activityInstanceId=${activityInstanceId}&carerString=${carerString}&start=${start_date}&end=${end_date}`);
    }


}