import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { BucketService, Bucket } from 'src/app/services/bucket.service';

@Component({
  selector: 'app-bucket-dropdown',
  templateUrl: './bucket-dropdown.component.html',
  styleUrls: ['./bucket-dropdown.component.css']
})
export class BucketDropdownComponent implements OnInit {
  buckets: Bucket[] = [];
  selectedBucket: Bucket | null = null;

  @Output() bucketChange: EventEmitter<Bucket> = new EventEmitter<Bucket>();

  constructor(private bucketService: BucketService) { }

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    this.bucketService.getBuckets().subscribe(buckets => {
      this.buckets = buckets;
      if (this.buckets.length > 0 && !this.selectedBucket) {
        this.selectBucket(this.buckets[0]);
      }
    });
  }

  selectBucket(bucket: Bucket) {
    this.selectedBucket = bucket;
    this.bucketChange.emit(bucket);
  }

  onBucketChange(event: any) {
    const bucketId = event.target.value;
    const bucket = this.buckets.find(b => b.ID === parseInt(bucketId, 10));
    if (bucket) {
      this.selectBucket(bucket);
    }
  }
}
