import { Component, OnInit } from '@angular/core';
import { StaffService, Role } from 'src/app/services/staff.service';
import { BucketService, Bucket, BucketRole } from 'src/app/services/bucket.service';

@Component({
  selector: 'app-role',
  templateUrl: './role.component.html',
  styleUrls: ['./role.component.css']
})
export class RoleComponent implements OnInit {
  roles: Role[] = [];
  buckets: Bucket[] = [];
  bucketRoles: BucketRole[] = [];
  rolesWithBuckets: { role: Role, buckets: Bucket[] }[] = [];
  displayedColumns: string[] = ['role'];

  constructor(
    private staffService: StaffService,
    private bucketService: BucketService
  ) { }

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    this.staffService.getRoles().subscribe(roles => {
      this.roles = roles;
      this.combineData();
    });
    this.bucketService.getBuckets().subscribe(buckets => {
      this.buckets = buckets;
      this.displayedColumns = ['role', ...this.buckets.map(b => b.Name)];
      this.combineData();
    });
    this.bucketService.getBucketRoles().subscribe(bucketRoles => {
      this.bucketRoles = bucketRoles;
      this.combineData();
    });
  }

  combineData() {
    if (this.roles.length && this.buckets.length && this.bucketRoles.length) {
      this.rolesWithBuckets = this.roles.map(r => ({
        role: r,
        buckets: this.bucketRoles
          .filter(br => br.RoleID === r.ID)
          .map(br => this.buckets.find(b => b.ID === br.BucketID))
          .filter(b => b !== undefined) as Bucket[]
      }));
    }
  }

  isChecked(bucketId: number, roleId: number): boolean {
    return this.bucketRoles.some(
      br => br.BucketID === bucketId && br.RoleID === roleId
    );
  }

  onCheckboxChange(event: any, bucketId: number, roleId: number) {
    if (event.checked) {
      const newBucketRole: BucketRole = { ID: 0, BucketID: bucketId, RoleID: roleId }; // ID is usually set by the backend
      this.bucketService.createBucketRole(newBucketRole).subscribe(response => {
        // console.log('Bucket role saved', response);
        this.bucketRoles.push(response);
        this.combineData();
      });
    } else {
      const bucketRoleToDelete = this.bucketRoles.find(
        br => br.BucketID === bucketId && br.RoleID === roleId
      );
      if (bucketRoleToDelete) {
        this.bucketService.deleteBucketRole(bucketRoleToDelete.ID).subscribe(response => {
          // console.log('Bucket role removed', response);
          this.bucketRoles = this.bucketRoles.filter(br => br.ID !== bucketRoleToDelete.ID);
          this.combineData();
        });
      }
    }
  }
}