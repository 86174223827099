import { Component, OnInit } from '@angular/core';
import { ActivityService, Activity } from 'src/app/services/activity.service';
import { BucketService, Bucket, BucketActivity } from 'src/app/services/bucket.service';

@Component({
  selector: 'app-activity',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.css']
})
export class ActivityComponent implements OnInit {
  activities: Activity[] = [];
  buckets: Bucket[] = [];
  bucketActivities: BucketActivity[] = [];
  bucketsWithActivities: { bucket: Bucket, activities: Activity[] }[] = [];

  displayedColumns: string[] = ['activity'];

  constructor(
    private activityService: ActivityService,
    private bucketService: BucketService
  ) { }

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    this.activityService.getActivities().subscribe(activities => {
      this.activities = activities.sort((a, b) => {
        const descA = a.ActivityDetailDescription || '';
        const descB = b.ActivityDetailDescription || '';
        return descA.localeCompare(descB);
      });
      this.checkAllDataLoaded();
    });
    this.bucketService.getBuckets().subscribe(buckets => {
      this.buckets = buckets;
      this.displayedColumns = ['activity', ...this.buckets.map(b => b.Name)];
      this.checkAllDataLoaded();
    });
    this.bucketService.getBucketActivities().subscribe(bucketActivities => {
      this.bucketActivities = bucketActivities;
      this.checkAllDataLoaded();
    });
  }

  checkAllDataLoaded() {
    if (this.activities.length && this.buckets.length && this.bucketActivities.length) {
      this.combineData();
    }
  }

  combineData() {
    if (this.activities.length && this.buckets.length && this.bucketActivities.length) {
      this.bucketsWithActivities = this.buckets.map(b => ({
        bucket: b,
        activities: this.bucketActivities
          .filter(ba => ba.BucketID === b.ID)
          .map(ba => this.activities.find(a => a.ID === ba.ActivityID))
          .filter(a => a !== undefined) as Activity[]
      }));
    }
  }

  isChecked(bucketId: number, activityId: number): boolean {
    return this.bucketActivities.some(
      ba => ba.BucketID === bucketId && ba.ActivityID === activityId
    );
  }

  onCheckboxChange(event: any, bucketId: number, activityId: number) {
    if (event.checked) {
      const newBucketActivity: BucketActivity = { ID: 0, BucketID: bucketId, ActivityID: activityId }; // ID is usually set by the backend
      this.bucketService.createBucketActivity(newBucketActivity).subscribe(response => {
        // console.log('Bucket activity saved', response);
        this.bucketActivities.push(response);
        this.combineData();
      });
    } else {
      const bucketActivityToDelete = this.bucketActivities.find(
        ba => ba.BucketID === bucketId && ba.ActivityID === activityId
      );
      if (bucketActivityToDelete) {
        this.bucketService.deleteBucketActivity(bucketActivityToDelete.ID).subscribe(response => {
          // console.log('Bucket activity removed', response);
          this.bucketActivities = this.bucketActivities.filter(ba => ba.ID !== bucketActivityToDelete.ID);
          this.combineData();
        });
      }
    }
  }

  updateeCase() {
    this.activityService.updateEcaseActivities().subscribe(() => {
      this.loadData();
      alert('Activity data has been refreshed from eCase');
    });
  }
}