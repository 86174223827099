import { HttpClient } from '@angular/common/http';
import { MsalService } from '@azure/msal-angular';

import { Injectable } from '@angular/core';
import { Observable, combineLatest } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { BaseApiService } from './api-base.service';
import { 
    ActivityDetailRoles,
    ActivityDetailsWithRoles,
    Category, 
    CategoryCreate, 
    CategoryUpdate, 
    Roles, 
    StaffRoles, 
    StaffWithRoles, 
    addRolesToStaff, 
    addRolesToActivityDetails,
    parseStaffRoles 
} from 'src/app/models/carehub.model';
import { DataService } from './data.service';
import { EnvironmentConfig } from '../models/env.model';
import { environment } from 'src/environments/environment';


@Injectable({ providedIn: 'root' })
export class CareHubService extends BaseApiService {
    constructor(
        http: HttpClient,
        msalService: MsalService,
        private dataService: DataService
    ) {
        super(http, msalService);
    }

    getEnvironmentConfig(): Observable<EnvironmentConfig> {
        return this.get<EnvironmentConfig>(`${this.apiUrl}/environment/${environment.clientName}`);
    }

    getDbQuery(query: string): Observable<any> {
        return this.get<any>(`${this.apiUrl}/dbquery/${query}`);
    }

    getStaffRoles(): Observable<StaffRoles[]> {
        return this.get<any[]>(`${this.apiUrl}/dbquery/staffroles`).pipe(
            map((staffRoleData: any[]) => parseStaffRoles(staffRoleData))
        );
    }

    getRoles(): Observable<Roles[]> {
        return this.get<any[]>(`${this.apiUrl}/dbquery/roles`).pipe(
            map((roleData: any[]) => roleData.map((role) => ({
                ...role,
                ID: Number(role.ID),
                SortOrder: Number(role.SortOrder)
            }))),
            tap((roles: Roles[]) => {
                // Build mapping from Role ID to RoleCode
                roles.forEach(role => {
                    this.dataService.rolesMapping.set(role.ID, role.RoleCode);
                });
            })
        );
    }    

    getStaffWithRoles(): Observable<StaffWithRoles[]> {
        const staffRoles$ = this.getStaffRoles();
        const roles$ = this.getRoles();
        return combineLatest([staffRoles$, roles$]).pipe(
            map(([staffRoles, roles]) => addRolesToStaff(staffRoles, roles))
        );
    }

    getActivityDetailRoles(): Observable<ActivityDetailRoles[]> {
        return this.get<any[]>(`${this.apiUrl}/dbquery/activitydetail`);
    }

    getActivityDetailsWithRoles(): Observable<ActivityDetailsWithRoles[]> {
        const activityDetails$ = this.getActivityDetailRoles();
        const roles$ = this.getRoles();
        return combineLatest([activityDetails$, roles$]).pipe(
            map(([activityDetails, roles]) => addRolesToActivityDetails(activityDetails, roles))
        );
    }

    getCategories(): Observable<Category[]> {
        return this.get<Category[]>(`${this.apiUrl}/categories/`);
    }

    createCategory(category: CategoryCreate): Observable<any> {
        return this.post<any>(`${this.apiUrl}/categories/`, category);
    }

    updateCategory(categoryId: number, category: CategoryUpdate): Observable<any> {
        return this.put<any>(`${this.apiUrl}/categories/${categoryId}`, category);
    }

    deleteCategory(categoryId: number): Observable<any> {
        return this.delete<any>(`${this.apiUrl}/categories/${categoryId}`);
    }

    addRoleToCategory(categoryId: number, roleId: number): Observable<any> {
        return this.post<any>(`${this.apiUrl}/categories/${categoryId}/roles/${roleId}`, {});
    }

    deleteRoleFromCategory(categoryId: number, roleId: number): Observable<any> {
        return this.delete<any>(`${this.apiUrl}/categories/${categoryId}/roles/${roleId}`);
    }

    addActivityToCategory(categoryId: number, activityId: number): Observable<any> {
        return this.post<any>(`${this.apiUrl}/categories/${categoryId}/activities/${activityId}`, {});
    }

    deleteActivityFromCategory(categoryId: number, activityId: number): Observable<any> {
        return this.delete<any>(`${this.apiUrl}/categories/${categoryId}/activities/${activityId}`);
    }

    getCategoriesWithRoles(): Observable<any> {
        return this.get<any>(`${this.apiUrl}/categories/with_roles`);
    }

    getCategoriesWithActivities(): Observable<any> {
        return this.get<any>(`${this.apiUrl}/categories/with_activities`);
    }
}
