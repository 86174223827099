import { Injectable } from '@angular/core';
import { BehaviorSubject, map } from 'rxjs';
import { getWeek } from 'date-fns';

@Injectable({
  providedIn: 'root'
})
export class DateService {
  private selectedDateSource = new BehaviorSubject<Date | null>(null);
  selectedDate$ = this.selectedDateSource.asObservable();  

  setSelectedDate(date: Date) {
    this.selectedDateSource.next(date);
  }

  getWeekNumber(): number {
    const date = this.selectedDateSource.value;
    if (!date || !(date instanceof Date)) return 0;
    return getWeek(date, { weekStartsOn: 1, locale: { options: { weekStartsOn: 1 } } });
  }

}