import { Injectable } from '@angular/core';
import { Observable, from, of, BehaviorSubject } from 'rxjs';
import { map, switchMap, catchError, tap } from 'rxjs/operators';
import { BaseApiService } from './api-base.service';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { MsalService } from '@azure/msal-angular';

@Injectable({
  providedIn: 'root'
})
export class GraphService extends BaseApiService {
  private isRosteringAllowedSubject = new BehaviorSubject<boolean>(false);
  isRosteringAllowed$ = this.isRosteringAllowedSubject.asObservable();

  private rosteringGroupId = 'b8ccdfd1-2721-4ae6-b551-061f050923ac';

  constructor(
    http: HttpClient,
    msalService: MsalService
  ) {
    super(http, msalService);
  }

  initializeService(): void {
    this.checkGroupMembership();
  }

  checkGroupMembership(): void {
    this.is_member_of(this.rosteringGroupId).subscribe(
      (isMember: boolean) => {
        this.isRosteringAllowedSubject.next(isMember);
      }
    );
  }

  is_member_of(objectId: string): Observable<boolean> {
    return this.getaccess<any>(`${environment.graphApi.uri}/me/memberOf/${objectId}`).pipe(
      map((res: any) => {
        if (res.error) {
          return false;
        } else if (res.id === objectId) {
          return true;
        } else {
          return false;
        }
      }),
      catchError((error) => {
        return of(false);
      })
    );
  }

  getIsRosteringAllowed(): boolean {
    return this.isRosteringAllowedSubject.getValue();
  }
}