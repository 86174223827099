import { Injectable } from '@angular/core';
import { format } from 'date-fns';
import { AppointmentStatus } from './appointmentStatus';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  constructor(private snackBar: MatSnackBar) { }

  openSnackBar(message: string, action: string) {
    const config = new MatSnackBarConfig();
    config.duration = 5000;
    config.verticalPosition = 'top';
    this.snackBar.open(message, action, config);
  }

  public getStoredValue(key: string, defaultValue: any) {
    const storedValue = localStorage.getItem(key);
    return storedValue ? JSON.parse(storedValue) : defaultValue;
  }

  public storeValue(key: string, value: any) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  saveLastUsedDate(date: Date | any) {
    if (date instanceof Date && !isNaN(date.getTime())) {
      localStorage.setItem('lastUsedDate', date.toISOString());
    } else {
      // if it's a string, try to convert it to a date
      try {
        const parsedDate = new Date(date);
        if (!isNaN(parsedDate.getTime())) {
          localStorage.setItem('lastUsedDate', parsedDate.toISOString());
        } else {
          console.error('Invalid date:', date);
        }
      } catch (error) {
        console.error('Error parsing date:', date, error);
        // if it's not a valid date, use the current date
        localStorage.setItem('lastUsedDate', new Date().toISOString());
      }
    }
  }

  getLastUsedDate(): Date {
    const storedDate = localStorage.getItem('lastUsedDate');
    return storedDate ? new Date(storedDate) : new Date();
  }

  areDatesEqual(date1: Date, date2: Date): boolean {
    return date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate() &&
      date1.getHours() === date2.getHours() &&
      date1.getMinutes() === date2.getMinutes() &&
      date1.getSeconds() === date2.getSeconds();
  }

  get_tooltip_text(start: Date, end: Date, appointment: any): string {
    let clientDetails: string = '';

    if (typeof appointment.client_name === 'string') {
      clientDetails = appointment.client_name
        .replace(/^\~\~/g, '<s title="cancelled">')
        .replace(/\~\~$/g, '</s>');
    } else if (appointment.client_name != null) {
      clientDetails = String(appointment.client_name)
        .replace(/^\~\~/g, '<s title="cancelled">')
        .replace(/\~\~$/g, '</s>');
    }

    const timeRange = `${format(start, 'HH:mm')} - ${format(end, 'HH:mm')}`;

    return `
        <div class="tooltip">
          <div class="tooltip-inner">

            <div class="tooltip-line">
              <span class="key"><img src="assets/clock-plus.png" height="12" class="icon"></span>
              <span>${timeRange}</span>
            </div>

            <div class="tooltip-line">
              <span class="key"><img src="assets/client.png" height="12" class="icon"></span>
              <span><b>${clientDetails}</b></span>
            </div>

            <div class="tooltip-line">
              <span class="key"><img src="assets/client.png" height="12" class="icon"></span>
              <span>Suggested Carer:<b>${appointment.full_details.suggested_carer_string}</b></span>
            </div>

            <div class="tooltip-line appointment-text-wrap">
              <span class="key"><img src="assets/service.png" height="12" class="icon"></span>
              <span class="appointment-text">${appointment.text}</span>
            </div>
            
          </div>
        </div>`;

  }


  getAppointmentHtml(start: Date, end: Date, appointment: any): string {
    const timeRange = `${format(start, 'HH:mm')} - ${format(end, 'HH:mm')}`;
    let clientDetails: string = '';

    if (typeof appointment.client_name === 'string') {
      clientDetails = appointment.client_name
        .replace(/^\~\~/g, '<s title="cancelled">')
        .replace(/\~\~$/g, '</s>');
    } else if (appointment.client_name != null) {
      clientDetails = String(appointment.client_name)
        .replace(/^\~\~/g, '<s title="cancelled">')
        .replace(/\~\~$/g, '</s>');
    }
    // <button class="revert-btn" (click)="revertTimingChange('${appointment.id}')">•</button>
    if (appointment.pending_timing_change) {
      const originalTimeRange = `${format(new Date(appointment.full_details.start_date), 'HH:mm')} - ${format(new Date(appointment.full_details.end_date), 'HH:mm')}`;
      return `
        <span class="cal_event_line_text">
          <div class="cal_event_line_text_inner">
            <div class="line">
              <span class="key"><img src="assets/clock-plus.png" height="12" class="icon"></span>
              <span style="color: red; font-weight: 700;">${timeRange}</span> 
              <span style="color: black;">(was ${originalTimeRange})</span>
            </div>
            <div class="line">
              <span class="key"><img src="assets/client.png" height="12" class="icon"></span>
              <span style="font-size:12px"><b>${clientDetails}</b></span>
            </div>
            <div class="line appointment-text-wrap"> 
              <span class="key"><img src="assets/service.png" height="12" class="icon"></span>
              <span class="appointment-text">${appointment.text}</span>
            </div>
          </div>
        </span>`;
    } else {
      return `
        <span class="cal_event_line_text">
          <div class="cal_event_line_text_inner">
            <div class="line">
              <span class="key"><img src="assets/clock-plus.png" height="12" class="icon"></span>
              <span>${timeRange}</span>
            </div>
            <div class="line">
              <span class="key"><img src="assets/client.png" height="12" class="icon"></span>
              <span style="font-size:12px"><b>${clientDetails}</b></span>
            </div>
            <div class="line appointment-text-wrap"> 
              <span class="key"><img src="assets/service.png" height="12" class="icon"></span>
              <span class="appointment-text">${appointment.text}</span>
            </div>
          </div>
        </span>`;
    }
  }

  getCssClassForEvent(appointment: any): string {
    let classNames = [];
    const statusClass = AppointmentStatus.getAppointmentClass(appointment);

    // if carer_string and suggested_carer_string are not null, and they don't match, add a class to highlight the difference
    if (appointment.carer_string == "99999999" && appointment.full_details.suggested_carer_string && appointment.carer_string !== appointment.full_details.suggested_carer_string) {
      classNames.push('suggested-carer-available');
    }
    if (statusClass) {
      classNames.push(statusClass);
    }
    if (appointment.isStaffEvent) {
      classNames.push('staff-event');
    }
    if (appointment.readonly) {
      classNames.push('readonly');
    }
    if (appointment.activity?.activityType === 'Leave') {
      classNames.push('leave');
    }
    if (appointment.activity?.activityType === 'Unavailability') {
      classNames.push('unavailable');
    }
    return classNames.join(' ');
  }
}
