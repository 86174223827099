import { Component, ChangeDetectorRef, ElementRef, OnInit, OnDestroy, ViewChild, ViewEncapsulation } from "@angular/core";
import { Subscription } from 'rxjs';
import { format } from 'date-fns';
import { MatDialog } from '@angular/material/dialog';

import { Bucket } from 'src/app/services/bucket.service';
import { CustomLightboxComponent } from 'src/app/components/ui_elements/custom-lightbox/custom-lightbox.component';
import { HelperService } from "src/app/services/helper.service";
import { StaffService, Staff } from "src/app/services/staff.service";
import { SchedulerService } from "src/app/services/scheduler.service";

import { PublishComponent } from "src/app/components/ui_elements/publish/publish.component";


@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-roster-timeline',
  templateUrl: './roster-timeline.component.html',
  styleUrls: ['./roster-timeline.component.css', './rosterView.css'],
  providers: [SchedulerService]
})
export class RosterTimelineComponent implements OnInit, OnDestroy {
  @ViewChild("scheduler_here", { static: true }) schedulerContainer!: ElementRef;
  @ViewChild(PublishComponent) publishComponent!: PublishComponent;
  selectedBucket: Bucket | null = null;
  bucketStaff: Staff[] = [];
  columnWidth: number = 40;
  pendingChangesCount: number = 0;
  darkMode: boolean = false;
  loaded_darkmode: boolean = false;
  autoAllocateKeyStaff: boolean = false;
  private subscriptions: Subscription = new Subscription();

  constructor(
    private helperService: HelperService,
    private schedulerService: SchedulerService,
    private staffService: StaffService,
    private cdr: ChangeDetectorRef,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    this.columnWidth = this.helperService.getStoredValue('columnWidth', 40);
    this.autoAllocateKeyStaff = this.helperService.getStoredValue('autoAllocateKeyStaff', true);
    this.schedulerService.setAutoAllocateKeyStaff(this.autoAllocateKeyStaff);
    this.schedulerService.initScheduler(this.schedulerContainer);

    const nextButton = document.querySelector('.dhx_cal_next_button');
    const prevButton = document.querySelector('.dhx_cal_prev_button');

    nextButton?.addEventListener('click', () => this.buttonClickHandler());
    prevButton?.addEventListener('click', () => this.buttonClickHandler());

    this.subscriptions.add(
      this.schedulerService.pendingChangesCount$.subscribe(count => {
        this.pendingChangesCount = count;
        if (this.publishComponent) {
          this.publishComponent.pendingChangesCount = count;
        }
        this.cdr.detectChanges(); // Force change detection
      })
    );

    this.subscriptions.add(
      this.schedulerService.publishComplete$.subscribe(complete => {
        if (complete) {
          this.onPublishComplete();
        }
      })
    );

    this.schedulerService.scheduler?.attachEvent("onBeforeLightbox", (id: string) => {
      const event = this.schedulerService.scheduler?.getEvent(id);
      this.showCustomLightbox(event);
      return false; // Prevent default lightbox
    });
  }

  buttonClickHandler() {
    if (this.selectedBucket) {
      this.schedulerService.changeDay(this.selectedBucket.ID);
    }
  }

  toggleDarkMode() {
    this.darkMode = !this.darkMode;
    this.schedulerService.setDarkMode(this.darkMode);
  }

  onAutoAllocateToggle() {
    this.schedulerService.setAutoAllocateKeyStaff(this.autoAllocateKeyStaff);
    this.helperService.storeValue('autoAllocateKeyStaff', this.autoAllocateKeyStaff);
    if (this.selectedBucket) {
      this.loadData();
    }
  }

  onBucketChange(bucket: Bucket) {
    this.selectedBucket = bucket;
    this.loadData();
  }

  loadData() {
    if (!this.selectedBucket) return;
    
    let schedulerDate = this.schedulerService.scheduler?.getState().date;
    if (!schedulerDate) {
      schedulerDate = this.helperService.getLastUsedDate() || new Date();
    }
  
    let formattedDate: string;
    try {
      formattedDate = format(schedulerDate, 'yyyy-MM-dd');
      // Save the successfully formatted date
      this.helperService.saveLastUsedDate(schedulerDate);
    } catch (error) {
      console.error('Error formatting date:', error);
      const fallbackDate = this.helperService.getLastUsedDate() || new Date();
      formattedDate = format(fallbackDate, 'yyyy-MM-dd');
      console.log('Using fallback date:', formattedDate);
    }
  
    this.staffService.getBucketStaff(this.selectedBucket.ID).subscribe(staff => {
      this.bucketStaff = staff;
      this.schedulerService.updateTimelineView(this.bucketStaff, this.columnWidth);
      if (!this.loaded_darkmode) {
        this.darkMode = this.helperService.getStoredValue('darkMode', false);
        this.schedulerService.setDarkMode(this.darkMode);
        this.loaded_darkmode = true;
      }
      this.schedulerService.loadTasks(formattedDate, this.selectedBucket!.ID);
    });
  }

  publishPendingChanges() {
    this.schedulerService.publishPendingChanges();
  }

  private onPublishComplete() {
    if (this.publishComponent) {
      this.publishComponent.onPublishComplete();
    }
  }

  changeColumnWidth(change: number) {
    this.columnWidth = Math.max(20, Math.min(100, this.columnWidth + change));
    this.helperService.storeValue('columnWidth', this.columnWidth);
    var timeline = this.schedulerService.scheduler?.getView();
    if (timeline) {
      timeline.column_width = this.columnWidth;
      this.schedulerService.scheduler?.setCurrentView();
    }
  }

  show_minical() {
    if (this.selectedBucket) {
      this.schedulerService.show_minical(this.selectedBucket.ID);
    }
  }

  showCustomLightbox(event: any) {
    const keyStaff = this.bucketStaff.find(staff => staff.ID === event.suggested_carer_string);
    const keyStaffName = keyStaff ? `${keyStaff.FirstName} ${keyStaff.LastName}` : 'Not assigned';

    const dialogRef = this.dialog.open(CustomLightboxComponent, {
      width: '400px',
      data: { event, bucketStaff: this.bucketStaff, keyStaffName }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.action === 'save') {
        this.schedulerService.updateKeyStaff(event.quote_detail_id, result.newKeyStaff).subscribe(
          response => {
            if (response.record_updated) {
              this.schedulerService.updateEventAfterKeyStaffChange(event.id, result.newKeyStaff);
              this.schedulerService.scheduler?.updateView(); // Force a redraw
              this.helperService.openSnackBar('Key staff updated successfully', 'Close');
            } else {
              this.helperService.openSnackBar('Failed to update key staff. Please try again or contact support.', 'Close');
            }
          },
          error => {
            console.error('Error updating key staff:', error);
            this.helperService.openSnackBar('Error updating key staff. Please try again or contact support.', 'Close');
          }
        );
      }
    });
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
    if (this.schedulerService.scheduler) this.schedulerService.scheduler.destructor();
  }
}
