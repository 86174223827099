// data.service.ts
import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class DataService {
    rolesMapping: Map<number, string> = new Map();
    rolePriority: string[] = [];
    roleKeyToUnallocatedKey: Map<string, number> = new Map();
    staffAndShiftKeys: Set<number> = new Set();
    staffIdToRoleKey: Map<number, string> = new Map();

    private dataLoadedSubject = new BehaviorSubject<boolean>(false);

    constructor() { }

    public setDataLoaded() {
        this.dataLoadedSubject.next(true);
    }

    public isDataLoaded(): Observable<boolean> {
        return this.dataLoadedSubject.asObservable();
    }
}
