<div class="staff-container">
  <div class="sort-controls">
    <button (click)="updateeCase()">Download from eCase</button>
    <button (click)="setSortType('name')">Sort by Name</button>
    <button (click)="setSortType('role')">Sort by Role</button>
  </div>
  <table class="staff-table">
    <thead>
      <tr>
        <th>Name</th>
        <th>Roles</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let staffItem of staffWithRoles">
        <td>{{ staffItem.staff.FirstName }} {{ staffItem.staff.LastName }}</td>
        <td>
          <ul class="roles-list">
            <li *ngFor="let role of staffItem.roles"
                [ngStyle]="{'background-color': staffItem.roleColors[role?.RoleDescription || '']}">
              {{ role?.RoleDescription }}
            </li>
          </ul>
        </td>
      </tr>
    </tbody>
  </table>
</div>