<div class="category-management">
  <div class="category-header">
    <div class="min-quals">Min. Quals</div>
    <div class="categories-title">Categories</div>
  </div>
  <div class="category-grid">
    <div class="category-row" *ngFor="let level of levels">
      <div class="level-name">{{ level.name }}</div>
      <div class="category-list"
           cdkDropList
           [cdkDropListData]="getCategoriesByLevel(level.id)"
           [id]="level.id.toString()"
           (cdkDropListDropped)="onDrop($event)"
           [cdkDropListConnectedTo]="connectedLists">
        <div *ngFor="let category of getCategoriesByLevel(level.id)"
             class="category-box"
             [style.background-color]="category.BaseColor"
             cdkDrag
             (dblclick)="openCreateEditDialog(category)">
          {{ category.Name }}
        </div>
      </div>
    </div>
  </div>
  <button mat-raised-button color="primary" (click)="openCreateEditDialog()">
    Create New Category
  </button>
</div>
