<h2 mat-dialog-title>Event Details</h2>
<mat-dialog-content>
    <p><strong>Client:</strong> {{ data.event.client_name }}</p>
    <p><strong>Start Time:</strong> {{ data.event.start_date | date:'shortTime' }}</p>
    <p><strong>End Time:</strong> {{ data.event.end_date | date:'shortTime' }}</p>
    <p><strong>Service:</strong> {{ data.event.text }}</p>
    <mat-form-field>
        <mat-label>Key Staff</mat-label>
        <mat-select [(ngModel)]="selectedKeyStaff">
            <mat-option *ngFor="let staff of data.bucketStaff" [value]="staff.ID">
                {{ staff.FirstName }} {{ staff.LastName }}
            </mat-option>
        </mat-select>
    </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button (click)="onCancel()">Cancel</button>
    <button mat-button color="primary" (click)="onSave()">Save</button>
</mat-dialog-actions>