import { Component, OnInit } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { MatDialog } from '@angular/material/dialog';
import { CategoryCreateEditComponent } from '../category-create-edit/category-create-edit.component';
import { CareHubService } from '../../../services/carehub.service';
import { Category, CategoryCreate, CategoryUpdate } from '../../../models/carehub.model';

@Component({
  selector: 'app-category-management',
  templateUrl: './category-management.component.html',
  styleUrls: ['./category-management.component.css']
})
export class CategoryManagementComponent implements OnInit {
  categories: Category[] = [];

  levels: { id: number, name: string }[] = [
    { id: 1, name: 'RN' },
    { id: 2, name: 'PCW' },
    { id: 3, name: 'None' }
  ];

  connectedLists: string[];

  constructor(
    private dialog: MatDialog,
    private careHubService: CareHubService
  ) {
    this.connectedLists = this.levels.map(l => l.id.toString());
  }

  ngOnInit(): void {
    this.loadCategories();
  }

  loadCategories(): void {
    this.careHubService.getCategories().subscribe(
      (categories) => {
        this.categories = categories;
        this.sortCategories();
      },
      (error) => console.error('Error loading categories:', error)
    );
  }

  sortCategories(): void {
    this.categories.sort((a, b) => a.Level - b.Level);
  }

  getCategoriesByLevel(level: number): Category[] {
    return this.categories.filter(category => category.Level === level);
  }

  onDrop(event: CdkDragDrop<Category[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
      
      const category = event.container.data[event.currentIndex];
      const newLevel = parseInt(event.container.id);
      
      if (category.Level !== newLevel) {
        const originalLevel = category.Level;
        category.Level = newLevel;
        this.updateCategoryLevel(category, originalLevel);
      }
    }
  }

  updateCategoryLevel(category: Category, originalLevel: number): void {
    const updateData: CategoryUpdate = {
      Name: category.Name,
      BaseColor: category.BaseColor,
      Level: category.Level
    };
    this.careHubService.updateCategory(category.ID, updateData).subscribe(
      () => {
        console.log(`Category ${category.Name} updated to level ${category.Level}`);
      },
      (error) => {
        console.error('Error updating category:', error);
        // Revert the change in the UI if the API call fails
        category.Level = originalLevel;
        this.sortCategories();
      }
    );
  }

  openCreateEditDialog(category?: Category): void {
    const dialogRef = this.dialog.open(CategoryCreateEditComponent, {
      width: '300px',
      data: category ? { ...category } : { Name: '', BaseColor: '#000000', Level: 3 }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result.action === 'save') {
          if (category) {
            this.updateCategory(category.ID, result.data);
          } else {
            this.createCategory(result.data);
          }
        } else if (result.action === 'delete' && category) {
          this.deleteCategory(category.ID);
        }
      }
    });
  }

  createCategory(categoryData: CategoryCreate): void {
    this.careHubService.createCategory(categoryData).subscribe(
      (newCategory) => {
        this.categories.push(newCategory);
        this.sortCategories();
      },
      (error) => console.error('Error creating category:', error)
    );
  }

  updateCategory(categoryId: number, categoryData: CategoryUpdate): void {
    const index = this.categories.findIndex(c => c.ID === categoryId);
    if (index !== -1) {
      const updatedCategory = { ...this.categories[index], ...categoryData };
      this.careHubService.updateCategory(categoryId, categoryData).subscribe(
        () => {
          this.categories[index] = updatedCategory;
          this.sortCategories();
        },
        (error) => {
          console.error('Error updating category:', error);
          // Revert changes in UI if API call fails
          this.loadCategories();
        }
      );
    }
  }

  deleteCategory(categoryId: number): void {
    const index = this.categories.findIndex(c => c.ID === categoryId);
    if (index !== -1) {
      const deletedCategory = this.categories[index];
      this.careHubService.deleteCategory(categoryId).subscribe(
        () => {
          this.categories.splice(index, 1);
        },
        (error) => {
          console.error('Error deleting category:', error);
          // Revert changes in UI if API call fails
          this.categories.splice(index, 0, deletedCategory);
        }
      );
    }
  }
}
