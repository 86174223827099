<div class="container">
  <h2>Categories</h2>

  <form [formGroup]="bucketForm" (ngSubmit)="onSubmit()">
    <div class="form-group">
      <label for="Name">Category Name</label>
      <input type="text" id="Name" formControlName="Name" class="form-control" placeholder="Enter category name">
    </div>
    <div class="form-group">
      <label for="BaseColor">Base Color</label>
      <input type="color" id="BaseColor" formControlName="BaseColor" class="form-control">
    </div>
    <button type="submit" class="btn btn-primary">{{ editing ? 'Update' : 'Create' }}</button>
    <button type="button" class="btn btn-secondary" (click)="resetForm()">Reset</button>
  </form>

  <hr>

  <ul class="list-group">
    <li *ngFor="let bucket of buckets" class="list-group-item">
      <div class="bucket-info">
        <span class="color-swatch" [style.backgroundColor]="bucket.BaseColor"></span>
        {{ bucket.Name }}
      </div>
      <div class="bucket-actions">
        <button class="btn btn-warning btn-sm" (click)="editBucket(bucket)">Edit</button>
        <button class="btn btn-danger btn-sm" (click)="deleteBucket(bucket.ID)">Delete</button>
      </div>
    </li>
  </ul>
</div>