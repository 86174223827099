<h2 mat-dialog-title>{{ data.ID ? 'Edit' : 'Create' }} Category</h2>
<form [formGroup]="categoryForm" (ngSubmit)="onSubmit()">
  <mat-dialog-content>
    <mat-form-field appearance="fill">
      <mat-label>Name</mat-label>
      <input matInput formControlName="Name" required>
      <mat-error *ngIf="categoryForm.get('Name')?.hasError('required')">
        Name is required
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>Color</mat-label>
      <input matInput type="color" formControlName="BaseColor" required>
      <mat-error *ngIf="categoryForm.get('BaseColor')?.hasError('required')">
        Color is required
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>Level</mat-label>
      <mat-select formControlName="Level" required>
        <mat-option [value]="1">RN</mat-option>
        <mat-option [value]="2">PCW</mat-option>
        <mat-option [value]="3">None</mat-option>
      </mat-select>
      <mat-error *ngIf="categoryForm.get('Level')?.hasError('required')">
        Level is required
      </mat-error>
    </mat-form-field>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-button (click)="onCancel()">Cancel</button>
    <button mat-raised-button color="warn" *ngIf="data.ID" (click)="onDelete()">Delete</button>
    <button mat-raised-button color="primary" type="submit" [disabled]="categoryForm.invalid">
      {{ data.ID ? 'Save' : 'Create' }}
    </button>
  </mat-dialog-actions>
</form>
