import { Injectable, ElementRef } from '@angular/core';
import { BehaviorSubject, Observable, from, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Scheduler, SchedulerStatic } from "@dhx/trial-scheduler";
import { format } from 'date-fns';
import { EventService } from './event.service';
import { CareHubService } from './carehub.service';
import { StaffWithRoles, Roles } from '../models/carehub.model';
import { DataService } from './data.service';
import { EnvironmentConfig, RoleDefinition, Section, RoleObject, StaffItem } from '../models/env.model';

@Injectable({
    providedIn: 'root'
})
export class RosterService {
    private _scheduler?: SchedulerStatic;
    private _schedulerContainer?: ElementRef;
    private _pendingChangesCount = new BehaviorSubject<number>(0);
    private _publishComplete = new BehaviorSubject<boolean>(false);
    private _autoAllocateKeyStaff: boolean = true;
    private _ydata: any[] = [];
    private _isLoading = new BehaviorSubject<boolean>(true);
    private lastLoadedDate: string | null = null;

    constructor(
        private eventService: EventService,
        private careHubService: CareHubService,
        private dataService: DataService
    ) { }

    setSchedulerContainer(container: ElementRef) {
        this._schedulerContainer = container;
    }

    initScheduler(yUnitData: any): void {
        if (!this._schedulerContainer) {
            throw new Error('Scheduler container is not set');
        }

        const scheduler = Scheduler.getSchedulerInstance();
        scheduler.plugins({
            multiselect: true,
            minical: true,
            timeline: true,
            tooltip: true,
            cookie: true,
            treetimeline: true
        });
        scheduler.config.date_format = '%Y-%m-%d %H:%i';
        scheduler.config.default_date = '%l, %j %F';
        scheduler.config.show_loading = true;
        scheduler.config.ajax_error = "console";
        scheduler.config.icons_select = [];
        scheduler.config.details_on_dblclick = true;
        scheduler.config.drag_create = false;
        scheduler.config.dblclick_create = false;
        scheduler.config.edit_on_create = false;
        scheduler.config.all_timed = true;
        scheduler.config.drag_resize = false;
        scheduler.config.drag_move = true;
        scheduler.config.mark_now = true;
        scheduler.locale.labels['timeline_tab'] = "Timeline";
        scheduler.locale.labels.section_description = "Section";
        scheduler.setSkin("material");

        scheduler.createTimelineView({
            section_autoheight: false,
            name: "timeline",
            x_unit: "minute",
            x_date: "%i",
            x_step: 10,
            x_size: 144,
            x_start: 0,
            x_length: 144,
            y_unit: yUnitData,
            folder_dy: 30,
            y_property: "carer_string",
            render: "tree",
            scrollable: true,
            scroll_position: new Date(),
            column_width: 40,
            second_scale: {
                x_unit: "hour",
                x_date: "%H:%i"
            }
        });

        scheduler.init(this._schedulerContainer.nativeElement, new Date(), "timeline");

        scheduler.templates.timeline_row_class = (section: any) => {
            if (section.children) {
                return "folder children";
            }
            return "";
        };

        scheduler.attachEvent("onViewChange", (newView: string, newDate: any) => {
            if (!(newDate instanceof Date)) {
                newDate = new Date(newDate);
            }
            if (isNaN(newDate.getTime())) {
                console.error('Invalid newDate:', newDate);
                newDate = new Date();
            }
    
            const formattedDate = format(newDate, 'yyyy-MM-dd');
            
            // Only update tasks if the date has changed
            if (formattedDate !== this.lastLoadedDate) {
                this.lastLoadedDate = formattedDate;
                this.loadTasks(formattedDate).subscribe(
                    () => { /* Tasks loaded successfully */ },
                    error => { console.error('Error loading tasks:', error); }
                );
            }
        });


        // scheduler.templates.event_bar_text = (start, end, appointment) => this.helperService.getAppointmentHtml(start, end, appointment);
        // scheduler.templates.tooltip_text = (start, end, appointment) => this.helperService.get_tooltip_text(start, end, appointment);
        scheduler.templates.event_class = (event_start_date, event_end_date, appointment) => appointment.css_class;
        scheduler.templates.tooltip_date_format = function (date) {
            var formatFunc = scheduler.date.date_to_str("%d %M %Y %H:%i");
            return formatFunc(date);
        }

        this._scheduler = scheduler;
    }

    loadTimelineData(): void {
        let schedulerDate = this._scheduler?.getState().date;
        if (!(schedulerDate instanceof Date)) {
            schedulerDate = new Date(schedulerDate);
        }
        if (isNaN(schedulerDate.getTime())) {
            console.error('Invalid schedulerDate:', schedulerDate);
            schedulerDate = new Date();
        }
        const formattedDate = format(schedulerDate, 'yyyy-MM-dd');
        this.loadTasks(formattedDate).subscribe(
            () => { /* Tasks loaded successfully */ },
            error => { console.error('Error loading timeline data:', error); }
        );
    }    


    loadTasks(date: string): Observable<void> {
        this._isLoading.next(true);
        return new Observable<void>(observer => {
            this.eventService.getTimelineEvents(date).subscribe(tasks => {
                this._scheduler?.clearAll();
                this.updatePendingChangesCount();
                if (tasks.length === 0) {
                    observer.next();
                    observer.complete();
                    return;
                }

                const processedTasks = tasks.map(task => {
                    const processedTask = {
                        careActivityInstanceId: task.ID,
                        text: task.activity_text,
                        start_date: task.start,
                        end_date: task.end,
                        carer_string: task.carer_string,
                        client_name: task.client_name,
                        client_id: task.client_id,
                        pending_allocation_change: task.pending_allocation_change,
                        pending_timing_change: task.pending_timing_change,
                        pending_change: task.pending_change,
                        quote_detail_id: task.quote_detail_id,
                        css_class: task.css_class,
                        section: task.eventHighestRoleKey,
                        full_details: task.full_details
                    };

                    if (this._autoAllocateKeyStaff &&
                        (task.carer_string !== null && parseInt(task.carer_string) > 100000) &&
                        task.full_details.suggested_carer_string &&
                        this.isCarerInTimeline(task.full_details.suggested_carer_string)) {
                        processedTask.carer_string = task.full_details.suggested_carer_string;
                        processedTask.pending_allocation_change = true;
                        processedTask.pending_change = true;
                    }

                    return processedTask;
                });

                this._scheduler?.parse(processedTasks);
                this.updatePendingChangesCount();
                this._isLoading.next(false);
                observer.next();
                observer.complete();
            },
                error => {
                    this._isLoading.next(false);
                    observer.error(error);
                }
            );
        });
    }

    private isCarerInTimeline(carerString: string): boolean {
        return this._ydata.some(role => role.children.some((section: Section) => section.children?.some((staff: StaffItem) => staff.key.toString() === carerString)));
    }

    get isLoading$(): Observable<boolean> {
        return this._isLoading.asObservable();
    }

    get scheduler(): SchedulerStatic | undefined {
        return this._scheduler;
    }

    get pendingChangesCount$(): Observable<number> {
        return this._pendingChangesCount.asObservable();
    }

    get publishComplete$(): Observable<boolean> {
        return this._publishComplete.asObservable();
    }

    setAutoAllocateKeyStaff(value: boolean) {
        this._autoAllocateKeyStaff = value;
    }

    private updatePendingChangesCount() {
        const events = this._scheduler?.getEvents();
        if (events) {
            const pendingCount = events.filter(event => event.pending_change).length;
            this._pendingChangesCount.next(pendingCount);
        }
    }

    getYunitData(): Observable<any[]> {
        return this.careHubService.getStaffWithRoles().pipe(
            switchMap((staffWithRoles: StaffWithRoles[]) => {
                return this.careHubService.getEnvironmentConfig().pipe(
                    map((config: EnvironmentConfig) => {
                        const environmentRoles = config;
                        const rolePriority = environmentRoles.rolePriority;

                        // Build the role data structure
                        let roleData: RoleObject[] = [];
                        let roleKeyCounter = 1000000;

                        // Map to keep track of sections
                        let roleSections: { [key: string]: Section } = {};

                        // Initialize DataService variables
                        this.dataService.rolePriority = rolePriority;
                        this.dataService.roleKeyToUnallocatedKey = new Map();
                        this.dataService.staffAndShiftKeys = new Set();
                        this.dataService.staffIdToRoleKey = new Map();

                        rolePriority.forEach((roleKey, index) => {
                            const role = environmentRoles[roleKey] as RoleDefinition;
                            roleKeyCounter++;
                            const roleMainKey = roleKeyCounter;

                            const roleObject: RoleObject = {
                                key: roleMainKey,
                                label: role.label,
                                open: true,
                                children: [],
                            };

                            // Unallocated sections
                            let unallocatedKeyCounter = 2000000 + index * 1000;
                            // Store mapping from roleKey to unallocated section key
                            this.dataService.roleKeyToUnallocatedKey.set(roleKey, unallocatedKeyCounter);

                            roleObject.children.push({
                                key: unallocatedKeyCounter,
                                label: role.unallocatedText,
                            });

                            // Extra Unallocated Categories
                            Object.keys(role.extraUnallocatedCategories).forEach(
                                (categoryLabel) => {
                                    unallocatedKeyCounter++;
                                    roleObject.children.push({
                                        key: unallocatedKeyCounter,
                                        label: categoryLabel,
                                    });
                                }
                            );

                            // Shift sections
                            let shiftSections: Section[] = [];
                            let shiftKeyCounter = 3000000 + index * 1000;

                            // Standard Shift Section
                            const standardShiftSection: Section = {
                                key: shiftKeyCounter,
                                label: role.shiftText,
                                children: [],
                            };
                            shiftSections.push(standardShiftSection);
                            roleSections[`${roleKey}_standardShift`] = standardShiftSection;

                            // Extra Shift Categories
                            Object.keys(role.extraShiftCategories).forEach((categoryLabel) => {
                                shiftKeyCounter++;
                                const shiftSection: Section = {
                                    key: shiftKeyCounter,
                                    label: categoryLabel,
                                    children: [],
                                };
                                shiftSections.push(shiftSection);
                                roleSections[`${roleKey}_extraShift_${categoryLabel}`] = shiftSection;
                            });

                            // Add shift sections
                            roleObject.children = roleObject.children.concat(shiftSections);

                            // Staff sections
                            let staffSections: Section[] = [];
                            let staffKeyCounter = 4000000 + index * 1000;

                            // Standard Staff Section
                            const standardStaffSection: Section = {
                                key: staffKeyCounter,
                                label: role.staffText,
                                children: [],
                            };
                            staffSections.push(standardStaffSection);
                            roleSections[`${roleKey}_standardStaff`] = standardStaffSection;

                            // Extra Staff Categories
                            Object.keys(role.extraStaffCategories).forEach((categoryLabel) => {
                                staffKeyCounter++;
                                const staffSection: Section = {
                                    key: staffKeyCounter,
                                    label: categoryLabel,
                                    children: [],
                                };
                                staffSections.push(staffSection);
                                roleSections[`${roleKey}_extraStaff_${categoryLabel}`] = staffSection;
                            });

                            // Add staff sections
                            roleObject.children = roleObject.children.concat(staffSections);

                            // Add to roleData
                            roleData.push(roleObject);
                        });

                        // Now assign staff to appropriate sections
                        staffWithRoles.forEach((staff) => {
                            const staffItem: StaffItem = {
                                key: staff.ID,
                                label: `${staff.FirstName} ${staff.LastName}`,
                            };

                            // Add staff ID to staffAndShiftKeys
                            this.dataService.staffAndShiftKeys.add(staff.ID);

                            const staffRoles = staff.Roles as Roles[];
                            const staffRoleCodes = staffRoles.map((role) => role.RoleCode);

                            // Determine staff's highest role
                            let staffHighestRoleKey: string | null = null;
                            for (const roleKey of rolePriority) {
                                const roleCodes = (environmentRoles[roleKey] as RoleDefinition).codes;
                                if (staffRoleCodes.some((code) => roleCodes.includes(code))) {
                                    staffHighestRoleKey = roleKey;
                                    break;
                                }
                            }

                            // Store mapping from staff ID to staffHighestRoleKey
                            if (staffHighestRoleKey) {
                                this.dataService.staffIdToRoleKey.set(staff.ID, staffHighestRoleKey);
                            } else {
                                return;
                            }

                            const role = environmentRoles[staffHighestRoleKey] as RoleDefinition;

                            const hasShiftCode = staffRoleCodes.includes(
                                (environmentRoles['shiftCode'] as string[])[0]
                            );

                            if (hasShiftCode) {
                                // Check extraShiftCategories
                                let addedToExtraShiftCategory = false;
                                for (const categoryLabel in role.extraShiftCategories) {
                                    const categoryCodes = role.extraShiftCategories[categoryLabel];
                                    if (staffRoleCodes.some((code) => categoryCodes.includes(code))) {
                                        // Add to this extra shift category
                                        const shiftSection =
                                            roleSections[
                                            `${staffHighestRoleKey}_extraShift_${categoryLabel}`
                                            ];
                                        if (shiftSection) {
                                            shiftSection.children!.push(staffItem);
                                            addedToExtraShiftCategory = true;
                                            break;
                                        }
                                    }
                                }
                                if (!addedToExtraShiftCategory) {
                                    // Add to standard shift category
                                    const shiftSection =
                                        roleSections[`${staffHighestRoleKey}_standardShift`];
                                    if (shiftSection) {
                                        shiftSection.children!.push(staffItem);
                                    }
                                }
                            } else {
                                // Check extraStaffCategories
                                let addedToExtraStaffCategory = false;
                                for (const categoryLabel in role.extraStaffCategories) {
                                    const categoryCodes = role.extraStaffCategories[categoryLabel];
                                    if (staffRoleCodes.some((code) => categoryCodes.includes(code))) {
                                        // Add to this extra staff category
                                        const staffSection =
                                            roleSections[
                                            `${staffHighestRoleKey}_extraStaff_${categoryLabel}`
                                            ];
                                        if (staffSection) {
                                            staffSection.children!.push(staffItem);
                                            addedToExtraStaffCategory = true;
                                            break;
                                        }
                                    }
                                }
                                if (!addedToExtraStaffCategory) {
                                    // Add to standard staff category
                                    const staffSection =
                                        roleSections[`${staffHighestRoleKey}_standardStaff`];
                                    if (staffSection) {
                                        staffSection.children!.push(staffItem);
                                    }
                                }
                            }
                        });

                        // Remove shift and staff sections with no children
                        roleData.forEach((roleObject) => {
                            roleObject.children = roleObject.children.filter((section) => {
                                if (section.key >= 3000000 && section.key < 4000000) {
                                    // Shift sections
                                    if (section.children && section.children.length > 0) {
                                        // Sort children
                                        section.children.sort((a, b) => a.label.localeCompare(b.label));
                                        return true;
                                    } else {
                                        return false;
                                    }
                                } else if (section.key >= 4000000) {
                                    // Staff sections
                                    if (section.children && section.children.length > 0) {
                                        // Sort children
                                        section.children.sort((a, b) => a.label.localeCompare(b.label));
                                        return true;
                                    } else {
                                        return false;
                                    }
                                } else {
                                    // Unallocated sections
                                    // Always include
                                    return true;
                                }
                            });
                        });

                        // After all data is set
                        this.dataService.setDataLoaded();
                        this._ydata = roleData;

                        return roleData;
                    })
                );
            })
        );
    }
}