<button (click)="updateeCase()">Download from eCase</button>
<mat-card class="activity-card">
  <table mat-table [dataSource]="activities" class="mat-elevation-z8">
    <ng-container matColumnDef="activity">
      <th mat-header-cell *matHeaderCellDef class="sticky-header"> Activity Detail Description </th>
      <td mat-cell *matCellDef="let activity"> {{ activity.ActivityDetailDescription }} </td>
    </ng-container>

    <ng-container *ngFor="let bucket of buckets" [matColumnDef]="bucket.Name">
      <th mat-header-cell *matHeaderCellDef class="sticky-header"> {{ bucket.Name }} </th>
      <td mat-cell *matCellDef="let activity">
        <mat-checkbox
          [checked]="isChecked(bucket.ID, activity.ID)"
          (change)="onCheckboxChange($event, bucket.ID, activity.ID)">
        </mat-checkbox>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</mat-card>