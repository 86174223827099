import { Injectable } from '@angular/core';
import { BaseApiService } from './api-base.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StatementService extends BaseApiService {

  getClients(): Observable<any[]> {
    return this.get<any[]>(`${this.apiUrl}/client/`);
  }

  generateStatements(clientIds: string[], date: Date): Observable<any> {
    const params = {
      clientIds: clientIds.join(','),
      statementDate: date.toISOString()
    };
    return this.post<any>(`${this.apiUrl}/generate-statements`, { params });
  }
}