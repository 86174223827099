import { Component, OnInit } from '@angular/core';
import { StatementService } from 'src/app/services/statement.service';

@Component({
  selector: 'app-statement-generator',
  templateUrl: './statement.component.html',
  styleUrls: ['./statement.component.css']
})
export class StatementComponent implements OnInit {
  clients: any[] = [];
  selectedClients: string[] = [];
  statementDate: Date = new Date();
  downloadLink: string | null = null;

  constructor(private statementService: StatementService) {}

  ngOnInit() {
    this.loadClients();
  }

  loadClients() {
    // Fetch clients from your API
    this.statementService.getClients().subscribe(
      clients => this.clients = clients
    );
  }

  generateStatements() {
    this.statementService.generateStatements(this.selectedClients, this.statementDate)
      .subscribe(
        response => {
          this.downloadLink = response.downloadLink;
        },
        error => {
          console.error('Error generating statements', error);
          // Handle error (e.g., show error message to user)
        }
      );
  }
}