import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Staff } from 'src/app/services/staff.service';
import { ConfirmationDialogComponent } from 'src/app/components/ui_elements/confirmation-dialog/confirmation-dialog.component'; // Adjust the path as needed

@Component({
  selector: 'app-custom-lightbox',
  templateUrl: './custom-lightbox.component.html',
  styleUrls: ['./custom-lightbox.component.css']
})
export class CustomLightboxComponent implements OnInit {
  selectedKeyStaff: string | number | undefined;

  constructor(
    public dialogRef: MatDialogRef<CustomLightboxComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog // Inject MatDialog
  ) { }

  ngOnInit() {
    if (this.data.bucketStaff && Array.isArray(this.data.bucketStaff)) {
      const suggestedCarerString = String(this.data.event.full_details.suggested_carer_string);
      const matchingStaff = this.data.bucketStaff.find((staff: Staff) => String(staff.ID) === suggestedCarerString);
      if (matchingStaff) {
        this.selectedKeyStaff = matchingStaff.ID;
      }
    } 
  }

  onSave() {
    if (String(this.selectedKeyStaff) !== String(this.data.event.suggested_carer_string)) {
      const confirmMessage = "This change will affect all future occurrences of this recurring event. Do you want to proceed?";
      
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: '300px',
        data: {
          title: 'Confirm Changes',
          message: confirmMessage
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.dialogRef.close({ action: 'save', newKeyStaff: this.selectedKeyStaff });
        }
      });
      
    } else {
      this.dialogRef.close();
    }
  }

  onCancel() {
    this.dialogRef.close();
  }
}
