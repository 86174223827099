import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Category, CategoryCreate, CategoryUpdate } from '../../../models/carehub.model';

@Component({
  selector: 'app-category-create-edit',
  templateUrl: './category-create-edit.component.html',
  styleUrls: ['./category-create-edit.component.css']
})
export class CategoryCreateEditComponent {
  categoryForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<CategoryCreateEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Category
  ) {
    this.categoryForm = this.fb.group({
      Name: [data.Name || '', Validators.required],
      BaseColor: [data.BaseColor || '#000000', Validators.required],
      Level: [data.Level || 3, Validators.required]
    });
  }

  onSubmit(): void {
    if (this.categoryForm.valid) {
      const formData = this.categoryForm.value;
      const result: { action: string, data: CategoryCreate | CategoryUpdate } = {
        action: 'save',
        data: formData
      };
      this.dialogRef.close(result);
    }
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onDelete(): void {
    this.dialogRef.close({ action: 'delete', id: this.data.ID });
  }
}
