<div class="todo-component">
    <div class="todo-text">
        <h1>ToDo:</h1>
        <p>Roster</p>
        <ul>
            <li>Check the order of processes when publishing changes when there is a potential overlap</li>
            <li>Color-code background for HPAC services, and HPAC shifts</li>
        </ul>

        <p>Key Staff</p>
        <ul>
            <li>API route to handle user requests to update the key staff</li>
            <li>Create an eCase function to update the keystaff, which can be called by the api handler</li>
        </ul>

        <p>QOL</p>
        <ul>
            <li>loading indicator while waiting on eCase server</li>
            <li>Adjust redirects after authentication, token expiry</li>
            <li>Welcome / login landing page</li>
        </ul>

        <p>Phase 2</p>
        <ul>
            <li>Schedule daily database sync routine to refresh data from eCase including clients, staff, roles, activities, key staff</li>
            <li>Mobile app page for after hours staff to reallocate services</li>
            <li>Consider alternative additional design for reallocation page that shows multiple staff, so services for a shift can be redistributed amongst multiple staff if necessary.</li>
            <li>As the user has unpublished edits, save those to the local browser cache, so if there is an unexpected reboot or browser crash, the unpublished changes can be restored</li>
            <li>Handle service with more than 1 carer allocated</li>
            <li>Handle allocating a service to more than 1 carer</li>
            <li>Handle when a service has more than one activity assigned, which may have different role requirements for reassigning the service to a different carer</li>
        </ul>
    </div>
</div>