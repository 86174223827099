import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { BaseApiService } from './api-base.service';

export interface Role {
    ID: number,
    RoleCode?: string,
    RoleDescription?: string,
    SortOrder?: number,
    IsRemoved?: number,
    staffCount?: number
}

export interface Staff {
    ID: number,
    StateID?: number,
    AuthenID?: number,
    FirstName?: string,
    LastName?: string,
    DepartmentID?: number,
    RegionID?: number,
    EmployeeID?: number
}

export interface StaffRole {
    ID?: number,
    RSRolesID?: number,
    RSAuthenID?: number
}

@Injectable({
    providedIn: 'root'
})
export class StaffService extends BaseApiService {
    private selectedStaffSubject = new BehaviorSubject<Staff[]>([]);
    selectedStaff$ = this.selectedStaffSubject.asObservable();

    selectStaff(staff: Staff) {
        let currentSelection = this.selectedStaffSubject.getValue();
        if (currentSelection.find(s => s.ID === staff.ID)) {
            currentSelection = currentSelection.filter(s => s.ID !== staff.ID); // Deselect if already selected
        } else if (currentSelection.length < 2) {
            currentSelection.push(staff); // Add new selection if less than two
        }
        this.selectedStaffSubject.next(currentSelection);
    }

    // Staff CRUD
    getStaff(): Observable<Staff[]> {
        return this.get<Staff[]>(`${this.apiUrl}/staff/`);
    }

    getBucketStaff(bucketId: number): Observable<Staff[]> {
        return this.get<Staff[]>(`${this.apiUrl}/staff/bucket/${bucketId}`);
    }

    getStaffMember(staffId: number): Observable<Staff> {
        return this.get<Staff>(`${this.apiUrl}/staff/${staffId}`);
    }

    createStaff(staff: Staff): Observable<Staff> {
        return this.post<Staff>(`${this.apiUrl}/staff/`, staff);
    }

    updateStaff(staffId: number, staff: Staff): Observable<Staff> {
        return this.put<Staff>(`${this.apiUrl}/staff/${staffId}`, staff);
    }

    deleteStaff(staffId: number): Observable<void> {
        return this.delete<void>(`${this.apiUrl}/staff/${staffId}`);
    }

     // Triggers the backend to refresh the staff roles in the app database from the eCase production environment
     updateEcaseStaff(): Observable<any> {
        return this.get<any>(`${this.apiUrl}/ecase-update/staff/`);
    }

    // StaffRole CRUD
    getStaffRoles(): Observable<StaffRole[]> {
        return this.get<StaffRole[]>(`${this.apiUrl}/staff-roles/`);
    }

    getStaffRole(staffRoleId: number): Observable<StaffRole> {
        return this.get<StaffRole>(`${this.apiUrl}/staff-role/${staffRoleId}`);
    }

    createStaffRole(staffRole: StaffRole): Observable<StaffRole> {
        return this.post<StaffRole>(`${this.apiUrl}/staff-role/`, staffRole);
    }

    updateStaffRole(staffRoleId: number, staffRole: StaffRole): Observable<StaffRole> {
        return this.put<StaffRole>(`${this.apiUrl}/staff-role/${staffRoleId}`, staffRole);
    }

    deleteStaffRole(staffRoleId: number): Observable<void> {
        return this.delete<void>(`${this.apiUrl}/staff-role/${staffRoleId}`);
    }

    // Triggers the backend to refresh the staff roles in the app database from the eCase production environment
    updateEcaseStaffRoles(): Observable<any> {
        return this.get<any>(`${this.apiUrl}/ecase-update/staff-role/`);
    }


    // Role CRUD
    getRoles(): Observable<Role[]> {
        return this.get<Role[]>(`${this.apiUrl}/roles/`);
    }

    getRole(roleId: number): Observable<Role> {
        return this.get<Role>(`${this.apiUrl}/role/${roleId}`);
    }

    createRole(role: Role): Observable<Role> {
        return this.post<Role>(`${this.apiUrl}/role/`, role);
    }

    updateRole(roleId: number, role: Role): Observable<Role> {
        return this.put<Role>(`${this.apiUrl}/role/${roleId}`, role);
    }

    deleteRole(roleId: number): Observable<void> {
        return this.delete<void>(`${this.apiUrl}/role/${roleId}`);
    }

    // Triggers the backend to refresh the roles in the app database from the eCase production environment
    updateEcaseRoles(): Observable<any> {
        return this.get<any>(`${this.apiUrl}/ecase-update/role/`);
    }

}