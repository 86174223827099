import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseApiService } from './api-base.service';

export interface Activity {
    ID: number,
    ActivityHeaderCode?: string,
    ActivityDetailCode?: string,
    ActivityDetailDescription?: string,
    IsParent?: number
}

@Injectable({
    providedIn: 'root'
})
export class ActivityService extends BaseApiService {

    // Activity CRUD
    getActivities(): Observable<Activity[]> {
        return this.get<Activity[]>(`${this.apiUrl}/activities/`);
    }

    getActivity(activityId: number): Observable<Activity> {
        return this.get<Activity>(`${this.apiUrl}/activity/${activityId}`);
    }

    createActivity(activity: Activity): Observable<Activity> {
        return this.post<Activity>(`${this.apiUrl}/activity/`, activity);
    }

    updateActivity(activityId: number, activity: Activity): Observable<Activity> {
        return this.put<Activity>(`${this.apiUrl}/activity/${activityId}`, activity);
    }

    deleteActivity(activityId: number): Observable<void> {
        return this.delete<void>(`${this.apiUrl}/activity/${activityId}`);
    }

     // Triggers the backend to refresh the staff roles in the app database from the eCase production environment
     updateEcaseActivities(): Observable<any> {
        return this.get<any>(`${this.apiUrl}/ecase-update/activity/`);
    }

}