import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BucketService, Bucket } from 'src/app/services/bucket.service';
import { StaffService, Staff, Role } from 'src/app/services/staff.service';
import { combineLatest } from 'rxjs';

@Component({
  selector: 'app-bucket-staff-dropdown',
  templateUrl: './bucket-staff-dropdown.component.html',
  styleUrls: ['./bucket-staff-dropdown.component.css']
})
export class BucketStaffDropdownComponent implements OnInit {
  @Input() selectedBucket: Bucket | null = null;
  @Output() staffChange: EventEmitter<Staff[]> = new EventEmitter<Staff[]>();

  staffWithRoles: { staff: Staff, roles: Role[] }[] = [];
  bucketStaff: Staff[] = [];
  filteredStaff: Staff[] = [];
  selectedStaff: Staff[] = [];

  constructor(
    private bucketService: BucketService,
    private staffService: StaffService
  ) { }

  ngOnInit() {
    if (this.selectedBucket) {
      this.loadBucketStaff(this.selectedBucket.ID);
    }
  }

  ngOnChanges() {
    if (this.selectedBucket) {
      this.loadBucketStaff(this.selectedBucket.ID);
    }
  }

  loadBucketStaff(bucketId: number) {
    combineLatest([
      this.staffService.getStaff(),
      this.staffService.getRoles(),
      this.staffService.getStaffRoles(),
      this.bucketService.getBucketRoles()
    ]).subscribe(([staff, roles, staffRoles, bucketRoles]) => {
      const relevantBucketRoles = bucketRoles.filter(br => br.BucketID === bucketId);
      const roleIds = relevantBucketRoles.map(br => br.RoleID);

      this.staffWithRoles = staff.map(s => {
        const rolesForStaff = staffRoles
          .filter(sr => sr.RSAuthenID === s.ID && roleIds.includes(sr.RSRolesID as number))
          .map(sr => roles.find(r => r.ID === sr.RSRolesID))
          .filter(r => r !== undefined) as Role[];
        return { staff: s, roles: rolesForStaff };
      }).filter(swr => swr.roles.length > 0);

      this.bucketStaff = this.staffWithRoles.map(swr => swr.staff);
      this.filteredStaff = this.bucketStaff;
      // sort staff by first name then last name
      this.bucketStaff.sort((a, b) => {
        if (a.FirstName && b.FirstName) {
          if (a.FirstName < b.FirstName) {
            return -1;
          } else if (a.FirstName > b.FirstName) {
            return 1;
          }
        }
        if (a.LastName && b.LastName && a.LastName < b.LastName) {
          return -1;
        } else if (a.LastName && b.LastName && a.LastName > b.LastName) {
          return 1;
        } else {
          return 0;
        }
      });
    });
  }

  selectStaff(staff: Staff) {
    if (this.selectedStaff.includes(staff)) {
      this.selectedStaff = this.selectedStaff.filter(s => s !== staff);
    } else {
      this.selectedStaff.push(staff);
    }
    this.staffChange.emit(this.selectedStaff);
  }

  onInput(event: Event) {
    const input = event.target as HTMLInputElement;
    const value = input.value;
    this.filteredStaff = this.bucketStaff.filter(staff => 
      `${staff.FirstName} ${staff.LastName}`.toLowerCase().includes(value.toLowerCase()));
  }
}
