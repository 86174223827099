import { NgModule, inject } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { BrowserUtils } from '@azure/msal-browser';
import { ProfileComponent } from 'src/app/pages/profile/profile.component';
import { HomeComponent } from 'src/app/home/home.component';
import { FailedComponent } from 'src/app/pages/failed/failed.component';

import { RosteringGuard } from 'src/app/guards/rostering.guard';

import { ActivityComponent } from 'src/app/components/base_elements/activity/activity.component';
import { BucketComponent } from 'src/app/components/base_elements/bucket/bucket.component';
import { BucketDropdownComponent } from 'src/app/components/ui_elements/bucket-dropdown/bucket-dropdown.component';
import { BucketStaffDropdownComponent } from 'src/app/components/ui_elements/bucket-staff-dropdown/bucket-staff-dropdown.component';
import { ReallocateComponent } from 'src/app/components/reallocate/reallocate.component';
import { RoleComponent } from 'src/app/components/base_elements/role/role.component';
import { RosterTimelineComponent } from 'src/app/components/roster-timeline/roster-timeline.component';
import { ShiftComponent } from 'src/app/components/base_elements/shift/shift.component';
import { StaffComponent } from 'src/app/components/base_elements/staff/staff.component';
import { StatementComponent } from 'src/app/components/statement/statement.component';
import { TodoComponent } from 'src/app/pages/todo/todo.component';


const routes: Routes = [
  {
    path: 'activity',
    component: ActivityComponent,
    canActivate: [MsalGuard, () => inject(RosteringGuard).canActivate()]
  },
  {
    path: 'bucket-dropdown',
    component: BucketDropdownComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'bucket-staff-dropdown',
    component: BucketStaffDropdownComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'bucket',
    component: BucketComponent,
    canActivate: [MsalGuard, () => inject(RosteringGuard).canActivate()]
  },
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'reallocate',
    component: ReallocateComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'role',
    component: RoleComponent,
    canActivate: [MsalGuard, () => inject(RosteringGuard).canActivate()]
  },
  {
    path: 'roster-timeline',
    component: RosterTimelineComponent,
    canActivate: [MsalGuard, () => inject(RosteringGuard).canActivate()]
  },
  {
    path: 'shift',
    component: ShiftComponent,
    canActivate: [MsalGuard, () => inject(RosteringGuard).canActivate()]
  },
  {
    path: 'staff',
    component: StaffComponent,
    canActivate: [MsalGuard, () => inject(RosteringGuard).canActivate()]
  },
  {
    path: 'statement',
    component: StatementComponent,
    canActivate: [MsalGuard, () => inject(RosteringGuard).canActivate()]
  },
  {
    path: 'todo',
    component: TodoComponent,
    canActivate: [MsalGuard, () => inject(RosteringGuard).canActivate()]
  },
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'login-failed',
    component: FailedComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    // Don't perform initial navigation in iframes or popups
    initialNavigation: !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup() ? 'enabledNonBlocking' : 'disabled' // Set to enabledBlocking to use Angular Universal
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
