import { Component, OnInit } from '@angular/core';
import { BucketService, Bucket, BucketShift } from 'src/app/services/bucket.service';
import { StaffService, Staff, Role, StaffRole } from 'src/app/services/staff.service';
import { forkJoin, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-shift',
  templateUrl: './shift.component.html',
  styleUrls: ['./shift.component.css']
})
export class ShiftComponent implements OnInit {
  staff: Staff[] = [];
  roles: Role[] = [];
  staffRoles: StaffRole[] = [];
  shifts: { staff: Staff, roles: Role[] }[] = [];
  buckets: Bucket[] = [];
  bucketShifts: BucketShift[] = [];
  bucketsWithShifts: { bucket: Bucket, shifts: Staff[] }[] = [];
  isLoading: boolean = true;
  error: string | null = null;

  displayedColumns: string[] = ['staff'];

  constructor(
    private bucketService: BucketService,
    private staffService: StaffService
  ) { }

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    this.error = null;
    this.isLoading = true;

    forkJoin({
      staff: this.staffService.getStaff().pipe(catchError(error => {
        this.error = 'Failed to load staff';
        return of([]);
      })),
      roles: this.staffService.getRoles().pipe(catchError(error => {
        this.error = 'Failed to load roles';
        return of([]);
      })),
      staffRoles: this.staffService.getStaffRoles().pipe(catchError(error => {
        this.error = 'Failed to load staff roles';
        return of([]);
      })),
      buckets: this.bucketService.getBuckets().pipe(catchError(error => {
        this.error = 'Failed to load buckets';
        return of([]);
      })),
      bucketShifts: this.bucketService.getBucketShifts().pipe(catchError(error => {
        this.error = 'Failed to load bucket shifts';
        return of([]);
      }))
    }).subscribe(({ staff, roles, staffRoles, buckets, bucketShifts }) => {
      this.staff = staff;
      this.roles = roles;
      this.staffRoles = staffRoles;
      this.buckets = buckets;
      this.bucketShifts = bucketShifts;

      this.displayedColumns = ['staff', ...this.buckets.map(b => b.Name)];

      this.combineData();
      this.isLoading = false;
    });
  }

  combineData() {
    this.shifts = this.staff.map(s => ({
      staff: s,
      roles: this.staffRoles
        .filter(sr => sr.RSAuthenID === s.ID)
        .map(sr => this.roles.find(r => r.ID === sr.RSRolesID))
        .filter(r => r !== undefined && r.RoleCode === 'SHFT') as Role[]
    })).filter(swr => swr.roles.length > 0);  // Filter to only include staff with the "SHFT" role

    // Sort by FirstName, then LastName
    this.shifts.sort((a, b) => {
      const firstNameComparison = a.staff.FirstName?.localeCompare(b.staff.FirstName ?? '') ?? 0;
      if (firstNameComparison !== 0) {
        return firstNameComparison;
      }
      return a.staff.LastName?.localeCompare(b.staff.LastName ?? '') ?? 0;
    });

    this.bucketsWithShifts = this.buckets.map(b => ({
      bucket: b,
      shifts: this.bucketShifts
        .filter(bs => bs.BucketID === b.ID)
        .map(bs => this.staff.find(s => s.ID === bs.StaffID))
        .filter(s => s !== undefined) as Staff[]
    }));
  }

  isChecked(bucketId: number, staffId: number): boolean {
    return this.bucketShifts.some(
      bs => bs.BucketID === bucketId && bs.StaffID === staffId
    );
  }

  onCheckboxChange(event: any, bucketId: number, staffId: number) {
    if (event.checked) {
      const newBucketShift: BucketShift = { ID: 0, BucketID: bucketId, StaffID: staffId }; // ID is usually set by the backend
      this.bucketService.createBucketShift(newBucketShift).subscribe(response => {
        // console.log('Bucket shift saved', response);
        this.bucketShifts.push(response);
        this.combineData();
      });
    } else {
      const bucketShiftToDelete = this.bucketShifts.find(
        bs => bs.BucketID === bucketId && bs.StaffID === staffId
      );
      if (bucketShiftToDelete) {
        this.bucketService.deleteBucketShift(bucketShiftToDelete.ID).subscribe(response => {
          // console.log('Bucket shift removed', response);
          this.bucketShifts = this.bucketShifts.filter(bs => bs.ID !== bucketShiftToDelete.ID);
          this.combineData();
        });
      }
    }
  }
}
