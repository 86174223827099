import { Component, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { HelperService } from 'src/app/services/helper.service'; // Import HelperService

@Component({
  selector: 'app-publish',
  templateUrl: './publish.component.html',
  styleUrls: ['./publish.component.css']
})
export class PublishComponent {
  @Input() set pendingChangesCount(value: number) {
    this._pendingChangesCount = value;
    this.cdr.detectChanges();
  }
  get pendingChangesCount(): number {
    return this._pendingChangesCount;
  }
  private _pendingChangesCount: number = 0;

  @Output() publishChanges = new EventEmitter<void>();

  publishing: boolean = false;

  constructor(
    private cdr: ChangeDetectorRef,
    private helperService: HelperService
  ) {}

  publish() {
    this.publishing = true;
    this.publishChanges.emit();
  }

  onPublishComplete() {
    this.publishing = false;
    this.helperService.openSnackBar('Changes published successfully', 'Close');
  }
}