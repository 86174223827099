import { Injectable, ElementRef } from '@angular/core';
import { BehaviorSubject, Observable, from, forkJoin } from 'rxjs';
import { mergeMap, tap, finalize } from 'rxjs/operators';
import { format } from 'date-fns';
import { Scheduler, SchedulerStatic } from "@dhx/trial-scheduler";

import { DateService } from './date.service';
import { HelperService } from './helper.service';
import { EventService } from './event.service';
import { Staff } from './staff.service';

@Injectable({
  providedIn: 'root'
})
export class SchedulerService {
  private _scheduler?: SchedulerStatic;
  private _pendingChangesCount = new BehaviorSubject<number>(0);
  private _publishComplete = new BehaviorSubject<boolean>(false);
  private _darkMode = false;
  private _autoAllocateKeyStaff: boolean = false;

  constructor(
    private helperService: HelperService,
    private dateService: DateService,
    private eventService: EventService
  ) { }

  get scheduler(): SchedulerStatic | undefined {
    return this._scheduler;
  }

  get pendingChangesCount$(): Observable<number> {
    return this._pendingChangesCount.asObservable();
  }

  get publishComplete$(): Observable<boolean> {
    return this._publishComplete.asObservable();
  }

  setAutoAllocateKeyStaff(value: boolean) {
    this._autoAllocateKeyStaff = value;
  }

  private updatePendingChangesCount() {
    const events = this._scheduler?.getEvents();
    if (events) {
      const pendingCount = events.filter(event => event.pending_change).length;
      this._pendingChangesCount.next(pendingCount);
    }
  }

  initScheduler(schedulerContainer: ElementRef): SchedulerStatic {
    const scheduler = Scheduler.getSchedulerInstance();
    scheduler.plugins({
      multiselect: true,
      minical: true,
      timeline: true,
      tooltip: true,
      cookie: true
    });
    scheduler.config.date_format = '%Y-%m-%d %H:%i';
    scheduler.config.default_date = '%l, %j %F';
    scheduler.config.show_loading = true;
    scheduler.config.ajax_error = "console";
    scheduler.config.icons_select = [];
    scheduler.config.details_on_dblclick = true;
    scheduler.config.drag_create = false;
    scheduler.config.dblclick_create = false;
    scheduler.config.edit_on_create = false;
    scheduler.config.all_timed = true;
    scheduler.config.drag_resize = false;
    scheduler.config.drag_move = true;
    scheduler.config.mark_now = true;
    scheduler.locale.labels['timeline_tab'] = "Timeline";

    const storedColumnWidth = this.helperService.getStoredValue('columnWidth', 40);

    scheduler.createTimelineView({
      name: "timeline",
      x_unit: "minute",
      x_date: "%i",
      x_step: 10, // 10 minutes step
      x_size: 144, // 144 * 10 minutes = 24 hours
      x_start: 0, // Start at 00:00
      x_length: 144, // Cover 24 hours
      y_unit: [],
      y_property: "carer_string",
      render: "bar",
      scrollable: true,
      scroll_position: new Date(),
      column_width: storedColumnWidth,
      second_scale: {
        x_unit: "hour",
        x_date: "%H:%i"
      }
    });

    let initialDate: Date = this.helperService.getLastUsedDate();
    scheduler.init(schedulerContainer.nativeElement, initialDate, "timeline");

    // scheduler.init(schedulerContainer.nativeElement, new Date(), "timeline");

    scheduler.attachEvent("onBeforeEventChanged", (ev, e, is_new, original) => {
      ev.pending_timing_change = !this.helperService.areDatesEqual(ev.start_date, new Date(original.full_details.start_date));
      ev.pending_allocation_change = String(ev.carer_string) !== String(ev.full_details.carer_string);
      ev.pending_change = ev.pending_timing_change || ev.pending_allocation_change;
      this.updatePendingChangesCount();
      return true;
    });

    scheduler.attachEvent("onBeforeLightbox", (id: string) => {
      const event = scheduler.getEvent(id);
      this.showCustomLightbox(event);
      return false; // Prevent the default lightbox from showing
    });

    scheduler.attachEvent("onViewChange", (newDate: Date) => {
      if (newDate instanceof Date && !isNaN(newDate.getTime())) {
        this.helperService.saveLastUsedDate(newDate);
        this.dateService.setSelectedDate(newDate);
      } else {
        // console.warn('Invalid date from onViewChange:', newDate);
        this.helperService.saveLastUsedDate(new Date());
      }
    });

    scheduler.templates.event_bar_text = (start, end, appointment) => this.helperService.getAppointmentHtml(start, end, appointment);
    scheduler.templates.tooltip_text = (start, end, appointment) => this.helperService.get_tooltip_text(start, end, appointment);
    scheduler.templates.event_class = (event_start_date, event_end_date, appointment) => appointment.css_class;
    scheduler.templates.tooltip_date_format = function (date) {
      var formatFunc = scheduler.date.date_to_str("%d %M %Y %H:%i");
      return formatFunc(date);
    }

    this._scheduler = scheduler;

    return scheduler;
  }

  private showCustomLightbox(event: any) {
    // Implement custom lightbox logic here
    // You'll need to create a modal or dialog component for this
    // console.log('Showing custom lightbox for event:', event);
  }

  updateKeyStaff(quote_detail_id: string, key_staff_id: string): Observable<any> {
    return this.eventService.updateKeyStaff(quote_detail_id, key_staff_id);
  }

  changeDay(bucketId: number) {
    const currentDate = this._scheduler?.getState().date;
    if (currentDate) {
      const newDate = new Date(currentDate);
      this.dateService.setSelectedDate(newDate);
      this.loadTasks(format(newDate, 'yyyy-MM-dd'), bucketId);
    }
  }

  loadTasks(date: string, bucketId: number) {
    if (!bucketId) return;

    this.eventService.getTimelineEvents(bucketId, date, date).subscribe(tasks => {
      this._scheduler?.clearAll();
      this.updatePendingChangesCount();
      if (tasks.length === 0) return;

      const processedTasks = tasks.map(task => {
        const processedTask = {
          careActivityInstanceId: task.ID,
          text: task.activity_text,
          start_date: task.start,
          end_date: task.end,
          carer_string: task.carer_string,
          client_name: task.client_name,
          client_id: task.client_id,
          pending_allocation_change: task.pending_allocation_change,
          pending_timing_change: task.pending_timing_change,
          pending_change: task.pending_change,
          quote_detail_id: task.quote_detail_id,
          css_class: task.css_class,
          full_details: task.full_details
        };

        if (this._autoAllocateKeyStaff &&
          task.carer_string === "99999999" &&
          task.full_details.suggested_carer_string &&
          this.isCarerInTimeline(task.full_details.suggested_carer_string)) {
          processedTask.carer_string = task.full_details.suggested_carer_string;
          processedTask.pending_allocation_change = true;
          processedTask.pending_change = true;
        }

        return processedTask;
      });

      this._scheduler?.parse(processedTasks);
      this.updatePendingChangesCount();
    });
  }

  setDarkMode(darkMode: boolean) {
    if (!this._scheduler) return;
    this._darkMode = darkMode;
    this._scheduler.setSkin(this._darkMode ? "dark" : "terrace");
    this.helperService.storeValue('darkMode', this._darkMode);
  }

  updateTimelineView(bucketStaff: Staff[], columnWidth: number, showUnassigned: boolean = true) {
    if (!this._scheduler) return;

    const ylist = bucketStaff.map(staff => ({ key: staff.ID, label: `${staff.FirstName} ${staff.LastName}` }));
    ylist.sort((a, b) => a.label.localeCompare(b.label));
    if (showUnassigned) ylist.push({ key: 99999999, label: "Unassigned" });

    if (this._scheduler.getView()) {
      this._scheduler.clearAll();
    }
    this._scheduler.createTimelineView({
      name: "timeline",
      x_unit: "minute",
      x_date: "%i",
      x_step: 10,
      x_size: 144,
      x_start: 0,
      x_length: 144,
      y_unit: ylist,
      y_property: "carer_string",
      render: "bar",
      scrollable: true,
      event_dy: "full",
      event_min_dy: 50,
      column_width: columnWidth,
      section_autoheight: false,
      dy: 62,
      dx: 150,
      second_scale: {
        x_unit: "hour",
        x_date: "%H:%i"
      }
    });

    this.helperService.storeValue('columnWidth', columnWidth);
    const currentDate = this._scheduler.getState().date;
    if (currentDate instanceof Date && !isNaN(currentDate.getTime())) {
      this._scheduler.setCurrentView(currentDate);
    } else {
      // console.warn('Invalid current date in scheduler state:', currentDate);
      this._scheduler.setCurrentView(new Date());
    }
  }

  private isCarerInTimeline(carerString: string): boolean {
    const timeline = this._scheduler?.getView();
    if (timeline && timeline.y_unit) {
      const result = timeline.y_unit.some((unit: any) => {
        return String(unit.key) === String(carerString);
      });
      return result;
    }
    return false;
  }

  show_minical(bucketId: number) {
    const scheduler = this._scheduler;
    if (!scheduler) return;

    if (scheduler.isCalendarVisible()) {
      scheduler.destroyCalendar();
    } else {
      scheduler.renderCalendar({
        position: "dhx_minical_icon",
        date: scheduler.getState().date,
        navigation: true,
        handler: (date: Date) => {
          const formattedDate = format(date, 'yyyy-MM-dd');
          scheduler.setCurrentView(date, 'timeline');
          this.dateService.setSelectedDate(date);
          this.loadTasks(formattedDate, bucketId);
          scheduler.destroyCalendar();
        }
      });
    }
  }

  publishPendingChanges() {
    const events = this._scheduler?.getEvents();
    if (!events) return;

    const pendingEvents = events.filter(event => event.pending_change && event.start_date >= new Date());
    const totalPending = pendingEvents.length;
    this._pendingChangesCount.next(totalPending);

    from(pendingEvents).pipe(
      mergeMap(event => {
        const timingPromise = event.pending_timing_change
          ? this.eventService.updateActivityTiming(String(event.careActivityInstanceId), event.carer_string, event.start_date, event.end_date).toPromise()
          : Promise.resolve(null);

        const allocationPromise = event.pending_allocation_change
          ? (event.carer_string === 99999999
            ? this.eventService.unallocateServiceInstance(String(event.careActivityInstanceId)).toPromise()
            : this.eventService.allocateServiceInstance(String(event.careActivityInstanceId), event.carer_string).toPromise())
          : Promise.resolve(null);

        return forkJoin([timingPromise, allocationPromise]).pipe(
          tap(([timingResponse, allocationResponse]) => {
            event.pending_timing_change = false;
            event.pending_allocation_change = false;
            event.pending_change = false;

            if (event.carer_string === 99999999) {
              event.css_class = 'appointment-unallocated';
            } else if (allocationResponse && allocationResponse[event.carer_string]?.IsAllocated) {
              event.css_class = 'appointment-approved';
            }

            this._scheduler?.updateEvent(event.id);
            const currentCount = this._pendingChangesCount.getValue();
            this._pendingChangesCount.next(currentCount - 1);
          })
        );
      }, 3), // Process 3 events concurrently
      finalize(() => {
        this._publishComplete.next(true);
        this.updatePendingChangesCount(); // Final check to ensure count is accurate
      })
    ).subscribe(
      () => { }, // Next handler (empty because we handle updates in the tap operator)
      (error) => alert('An error occurred while publishing changes:' + error),
      () => console.log('Publish process completed')
    );
  }

  updateEventAfterKeyStaffChange(eventId: string, newKeyStaff: string) {
    const event = this._scheduler?.getEvent(eventId);
    if (event) {
      // Update all relevant properties
      event.full_details.suggested_carer_string = String(newKeyStaff);
      event.carer_string = String(newKeyStaff);
      event.pending_allocation_change = true;
      event.pending_change = true;

      // Update the event in the scheduler
      this._scheduler?.updateEvent(event.id);

      // Force a redraw of the scheduler view
      this._scheduler?.updateView();

      // Update the pending changes count
      this.updatePendingChangesCount();
    }
  }

  revertTimingChange(eventId: string) {
    const event = this._scheduler?.getEvent(eventId);
    console.log(`Reverting timing change for event ${eventId}`);
    if (event) {
      event.start_date = event.full_details.original_start_date;
      event.end_date = event.full_details.original_end_date;
      event.pending_timing_change = false;
      event.pending_change = event.pending_allocation_change;
      this._scheduler?.updateEvent(event.id);
      this.updatePendingChangesCount();
    }
  }

  moveAllEvents(bucketStaff: Staff[], direction: any) {
    if (!this._scheduler || bucketStaff.length != 2) return;
    const ylist = bucketStaff.map(staff => ({ key: staff.ID, label: `${staff.FirstName} ${staff.LastName}` }));
    ylist.sort((a, b) => a.label.localeCompare(b.label));
    const topStaffId = ylist[0].key;
    const bottomStaffId = ylist[ylist.length - 1].key;

    const events = this._scheduler.getEvents();
    events.forEach(event => {
      if (direction === 'top') {
        if (event.carer_string === String(bottomStaffId)) {
          event.carer_string = String(topStaffId);
          event.pending_allocation_change = true;
          event.pending_change = true;
        }
      } else {
        if (event.carer_string === String(topStaffId)) {
          event.carer_string = String(bottomStaffId);
          event.pending_allocation_change = true;
          event.pending_change = true;
        }
      }
    });
    this.updatePendingChangesCount();
    this._scheduler.updateView();
  } // end of moveAllEvents
}
