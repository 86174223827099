<app-bucket-dropdown (bucketChange)="onBucketChange($event)"></app-bucket-dropdown>
<app-bucket-staff-dropdown [selectedBucket]="selectedBucket" (staffChange)="onStaffChange($event)"></app-bucket-staff-dropdown>

<div id="CommunityRosterNew">
    <div #scheduler_here class="dhx_cal_container" style="width: 100%; height: 40vh;">
        <div class="dhx_cal_navline">
            <div class="dhx_cal_prev_button">&nbsp;</div>
            <div class="dhx_cal_next_button">&nbsp;</div>
            <div class="dhx_cal_today_button"></div>
            <div class="dhx_minical_icon" id="dhx_minical_icon" (click)="show_minical()">&nbsp;</div>
            <div class="dhx_cal_date"></div>
            <div class="custom_nav_buttons">
                <button (click)="moveAll('top')">▲</button>
                <button (click)="moveAll('bottom')">▼</button>
                <span>{{ columnWidth }}px </span>
                <button (click)="changeColumnWidth(-20)">-</button>
                <button (click)="changeColumnWidth(20)">+</button>
            </div>
            <app-publish [pendingChangesCount]="pendingChangesCount" (publishChanges)="publishPendingChanges()"></app-publish>
        </div>
        <div class="dhx_cal_header"></div>
        <div class="dhx_cal_data"></div>
    </div>
</div>
