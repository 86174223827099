<div class="carehub-container">
  <mat-card>
    <mat-card-title>CareHub Staff and Roles</mat-card-title>
    <mat-card-content>
      <button mat-raised-button color="primary" (click)="loadData()">Refresh Data</button>

      <h3>Staff Details</h3>
      <table mat-table [dataSource]="staffWithRoles" class="mat-elevation-z8">
        <ng-container matColumnDef="carerId">
          <th mat-header-cell *matHeaderCellDef>Carer ID</th>
          <td mat-cell *matCellDef="let element">{{element.ID}}</td>
        </ng-container>
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>Name</th>
          <td mat-cell *matCellDef="let element">{{element.FirstName}} {{element.LastName}}</td>
        </ng-container>
        <ng-container matColumnDef="roles">
          <th mat-header-cell *matHeaderCellDef>Roles</th>
          <td mat-cell *matCellDef="let element">
            <ul>
              <li *ngFor="let role of element.Roles">{{role.RoleDescription}}</li>
            </ul>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="staffColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: staffColumns;"></tr>
      </table>

      <p *ngIf="staffWithRoles.length === 0">No staff data available. Click 'Refresh Data' to load.</p>

      <div class="summary-section">
        <h3 class="summary-header">
          Role Summary
          <button mat-icon-button (click)="toggleSummary()">
            <mat-icon>{{isSummaryExpanded ? 'expand_less' : 'expand_more'}}</mat-icon>
          </button>
        </h3>
        <div class="summary-content" [ngClass]="{'expanded': isSummaryExpanded, 'collapsed': !isSummaryExpanded}">
          <table mat-table [dataSource]="roleSummary" class="mat-elevation-z8">
            <ng-container matColumnDef="roleDescription">
              <th mat-header-cell *matHeaderCellDef>Role Description</th>
              <td mat-cell *matCellDef="let element">{{element.roleDescription}}</td>
            </ng-container>
            <ng-container matColumnDef="count">
              <th mat-header-cell *matHeaderCellDef>Count of Staff</th>
              <td mat-cell *matCellDef="let element">{{element.count}}</td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="summaryColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: summaryColumns;"></tr>
          </table>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>