<mat-toolbar color="primary">
  <img src="assets/logo.svg" alt="logo" class="logo" />
  <span class="header-title"><span class="libre-franklin-regular">care</span><span class="libre-franklin-extralight">hub systems</span></span>
  <div class="toolbar-spacer"></div>

  <button *ngIf="isRosteringAllowed" mat-button [routerLink]="['todo']">ToDo</button>
  <button *ngIf="isRosteringAllowed" mat-button [matMenuTriggerFor]="manageMenu">Manage</button>
  <mat-menu #manageMenu="matMenu">
    <button mat-menu-item [routerLink]="['carehub']">CareHub</button>
    <button mat-menu-item [routerLink]="['bucket']">Manage Categories</button>
    <button mat-menu-item [routerLink]="['activity']">Manage Activities</button>
    <button mat-menu-item [routerLink]="['role']">Manage Roles</button>
    <button mat-menu-item [routerLink]="['shift']">Manage Shifts</button>
    <button mat-menu-item [routerLink]="['staff']">View Staff</button>   
    <button mat-menu-item [routerLink]="['settings']">Settings</button>
    <button mat-menu-item [routerLink]="['roster']">Roster</button>
  </mat-menu>
  <button *ngIf="isRosteringAllowed" mat-raised-button [routerLink]="['roster-timeline']">Roster</button>
  <button mat-raised-button [routerLink]="['reallocate']">Reallocate</button>  
  <button mat-raised-button [matMenuTriggerFor]="loginMenu" *ngIf="!loginDisplay">Login</button>
  <mat-menu #loginMenu="matMenu">
    <button mat-menu-item (click)="loginRedirect()">Login using Redirect</button>
    <button mat-menu-item (click)="loginPopup()">Login using Popup</button>
  </mat-menu>

  <button mat-raised-button [matMenuTriggerFor]="logoutMenu" *ngIf="loginDisplay">Logout</button>
  <mat-menu #logoutMenu="matMenu">
    <button mat-menu-item (click)="logout()">Logout using Redirect</button>
    <button mat-menu-item (click)="logout(true)">Logout using Popup</button>
  </mat-menu>
</mat-toolbar>

<div class="container">
  <!--This is to avoid reload during acquireTokenSilent() because of hidden iframe -->
  <router-outlet *ngIf="!isIframe"></router-outlet>
</div>
