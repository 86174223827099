// api.service.ts
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, from, of } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { MsalService } from '@azure/msal-angular';
import { AuthenticationResult } from '@azure/msal-browser';

@Injectable({
  providedIn: 'root'
})
export class BaseApiService {
  protected apiUrl = environment.backend.uri;

  constructor(private http: HttpClient, private msalService: MsalService) { }

  private getAuthHeaders(): HttpHeaders {
    const account = this.msalService.instance.getActiveAccount();
    const token = account ? this.msalService.instance.getAccountByHomeId(account.homeAccountId)?.idToken : '';

    return new HttpHeaders({
      Authorization: `Bearer ${token}`
    });
  }

  private getAccessTokenHeaders(): Observable<HttpHeaders> {
    return from(this.msalService.acquireTokenSilent({
      scopes: environment.graphApi.scopes,
      account: this.msalService.instance.getActiveAccount()!
    })).pipe(
      map((result: AuthenticationResult) => {
        return new HttpHeaders({
          Authorization: `Bearer ${result.accessToken}`
        });
      })
    );
  }

  protected ensureValidToken(): Observable<void> {
    const account = this.msalService.instance.getActiveAccount();
    if (account) {
      const tokenExpiry = new Date(account.idTokenClaims?.exp ? account.idTokenClaims.exp * 1000 : 0);
      const now = new Date();
      const tokenValid = tokenExpiry > now;

      if (!tokenValid) {
        return from(this.msalService.acquireTokenSilent({
          account: account,
          scopes: environment.graphApi.scopes
        })).pipe(
          switchMap((result: AuthenticationResult) => {
            if (result.expiresOn) {
              localStorage.setItem('token_expiry', result.expiresOn.toISOString());
            }
            return of(void 0);
          }),
          catchError(() => {
            this.msalService.loginPopup();
            throw new Error('Token renewal required.');
          })
        );
      }
    }
    return of(void 0);
  }

  protected get<T>(url: string): Observable<T> {
    return this.ensureValidToken().pipe(
      switchMap(() => this.http.get<T>(url, { headers: this.getAuthHeaders() }))
    );
  }

  protected getaccess<T>(url: string): Observable<T> {
    return this.ensureValidToken().pipe(
      switchMap(() => this.getAccessTokenHeaders()),
      switchMap(headers => this.http.get<T>(url, { headers }))
    );
  }

  protected post<T>(url: string, body: any): Observable<T> {
    return this.ensureValidToken().pipe(
      switchMap(() => this.http.post<T>(url, body, { headers: this.getAuthHeaders() }))
    );
  }

  protected put<T>(url: string, body: any): Observable<T> {
    return this.ensureValidToken().pipe(
      switchMap(() => this.http.put<T>(url, body, { headers: this.getAuthHeaders() }))
    );
  }

  protected delete<T>(url: string): Observable<T> {
    return this.ensureValidToken().pipe(
      switchMap(() => this.http.delete<T>(url, { headers: this.getAuthHeaders() }))
    );
  }

}